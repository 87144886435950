const SocialIcons = {
    "medium": "/assets/socials/medium.png",
    "discord": "/assets/socials/discord.png",
    "youtube": "/assets/socials/youtube.png",
    "github": "/assets/socials/github.png",
    "reddit": "/assets/socials/reddit.png",
    "twitter": "/assets/socials/twitter.png",
    "facebook": "/assets/socials/facebook.png",
    "telegram": "/assets/socials/telegram.png",
    "instagram": "/assets/socials/instagram.png",
}

const Projects = {
    "Neo": {
        id: "Neo",
        slug:"neo",
        name: "Neo",
        icon_url:"/assets/logos/neo.png",
        short_description:"About neo",
        category:["Projct"],
        description:`Neo is an open-source, community-driven blockchain platform dedicated to fostering the Smart Economy by streamlining the development of decentralized applications and facilitating asset digitization via smart contracts.

        Featuring seamless integration with popular languages and tools, Neo provides a comprehensive platform for full-stack dApp development. With native support for decentralized storage, oracles, and domain name services, Neo envisions a future where technology bridges the gap between the physical and digital worlds, enabling data, goods, and services to flow securely, freely, and equitably.
        
        Committed to being the most developer-friendly blockchain, Neo's mission is to empower developers with the tools needed to build the Next-Generation Internet, ensuring universal access to all assets, digital or physical, with minimal trust and permission requirements.`,
        website_url:"https://neo.org",
        socials:{
            "twitter": {
                url:"https://twitter.com/Neo_Blockchain",
            },
            "discord":{
                url:"https://discord.com/invite/rvZFQ5382k"
            },
            "telegram":{
                url:"https://t.me/NEO_EN"
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:"https://www.reddit.com/r/NEO/"
            },
            "github":{
                url:"https://github.com/neo-project"
            },
            "medium":{
                url:"https://medium.com/neo-smart-economy"
            },
        }
    },

    "Flamingo": {
        id: "Flamingo",
        slug:"flamingo",
        name: "Flamingo Finance",
        icon_url:"/assets/logos/flamingo.png",
        short_description:"An easy-to-use DeFi platform .",
        category:["DeFi"],
        description:`Flamingo Finance is a user-friendly DeFi platform and decentralized exchange developed on the Neo N3 blockchain. Designed with Smart Functions for users of all skill levels, from beginners to seasoned cryptocurrency enthusiasts, the platform enables trading, liquidity provision, and token staking to earn yield in FLM, Flamingo's reward token. Additionally, Flamingo Finance now offers Flamingo Lend, a feature that allows users to borrow FUSD, Flamingo's over-collateralized stablecoin.`,
        website_url:"https://flamingo.finance",
        socials:{
            "twitter": {
                url:"https://twitter.com/FlamingoFinance",
            },
            "discord":{
                url:"https://discord.gg/4XNqQjwEjF"
            },
            "telegram":{
                url:"https://t.me/flamingo_finance"
            },
            "youtube":{
                url:"https://www.youtube.com/@FlamingoFinance"
            },
            "reddit":{
                url:"https://www.reddit.com/r/FlamingoDeFi/"
            },
            "github":{
                url:"https://github.com/flamingo-finance/"
            },
            "medium":{
                url:"https://medium.com/flamingo-finance"
            },
        }
    },

    "GhostMarket": {
        id: "GhostMarket",
        slug:"ghostmarket",
        name: "GhostMarket",
        icon_url:"/assets/logos/ghostmarket.png",
        short_description:"NFT marketplace, powered by smart contracts.",
        category:["NFT", "Marketplace"],
        description: `GhostMarket is a fully non-custodial NFT marketplace, powered by smart contracts across integrated blockchains. It features a user-friendly self-minting platform, enabling artists and developers to create NFTs while managing royalty percentages, attributes, locked content, and more. Catering to both NFT collectors and casual buyers, GhostMarket allows users to easily filter and sort listings from multiple blockchains.`,
        website_url:"https://ghostmarket.io",
        socials:{
            "twitter": {
                url:"https://twitter.com/ghostmarketio",
            },
            "discord":{
                url:"https://gmio.info/discord"
            },
            "telegram":{
                url:"https://t.me/ghostmarketio"
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:"https://www.reddit.com/r/ghostmarket/"
            },
            "github":{
                url:"https://github.com/onblockio"
            },
            "medium":{
                url:"https://medium.com/ghostmarket"
            },
        }
    }, 
    "FTW": {
        id: "FTW",
        slug:"ftw",
        name: "Forthewin Network",
        icon_url:"/assets/logos/ftw.png",
        short_description:"Create tokens code-free & access DeFi tools.",
        category:["DeFi", "NFT"],
        description: `ForTheWin Network enables crypto startups and enthusiasts to harness the power of DeFi by providing accessible tools for custom token creation, even without coding expertise. Effortlessly swap, farm, and lock tokens within a secure, all-in-one platform. Experience the full potential of DeFi by joining the ForTheWin Network today.`,
        website_url:"https://www.forthewin.network",
        socials:{
            "twitter": {
                url:"https://twitter.com/N3_FTW_NETWORK",
            },
            "discord":{
                url:"https://discord.gg/2Yv3zGNFYC"
            },
            "telegram":{
                url:""
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:""
            },
            "github":{
                url:"https://github.com/ForTheWinn"
            },
            "medium":{
                url:"https://medium.com/@Forthewin_network"
            },
        }
    },

    "Greenfinch": {
        id: "Greenfinch",
        slug:"greenfinch",
        name: "Greenfinch",
        icon_url:"/assets/logos/greenfinch.png",
        short_description:"Decentralized file storage platform",
        category:["File Storage", "Infrastructure"],
        description:`Greenfinch is a decentralized file storage platform built on the Neo blockchain, utilizing NeoFS. Our platform allows users to store their content on a decentralized network, granting permissioned access for increased control and security.`,
        website_url:"https://greenfinch.app",
        socials:{
            "twitter": {
                url:"https://twitter.com/GreenfinchApp",
            },
            "discord":{
                url:"https://discord.gg/EeZtA2b7PQ"
            },
            "telegram":{
                url:""
            },
            "youtube":{
                url:"https://www.youtube.com/@GreenfinchApp"
            },
            "reddit":{
                url:""
            },
            "github":{
                url:"https://github.com/configwizard/greenfinch.react"
            },
            "medium":{
                url:""
            },
        }
    },

    "COZ": {
        id: "COZ",
        slug:"coz",
        name: "COZ",
        icon_url:"/assets/logos/coz.png",
        short_description:"Web3 software product and development group",
        category:["Tooling", "Product"],
        description:`COZ is a leading software development company with a rich history in the blockchain industry, with contributors from all over the world. Founded in 2017, COZ has been at the forefront of introducing Neo blockchain to the world. Their contributions, including the first-ever light wallet and blockchain explorer, Python and JavaScript SDKs, community management, and workshops across multiple continents, have made it possible for people to experience the full potential of the Neo ecosystem.`,
        website_url:"https://coz.io",
        socials:{
            "twitter": {
                url:"https://twitter.com/coz_official",
            },
            "discord":{
                url:""
            },
            "telegram":{
                url:""
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:""
            },
            "github":{
                url:"https://github.com/cityOfZion/"
            },
            "medium":{
                url:""
            },
        }
    },

    "GrantShares": {
        id: "GrantShares",
        slug:"axlabs",
        name: "AxLabs",
        icon_url:"/assets/logos/axlabs.png",
        short_description:"Empowering builders.",
        category:["DeveloperExperience", "DAO", "Grants"],
        description:`AxLabs is a boutique software engineering company based in Switzerland specializing in cutting-edge blockchain technology. Since joining Neo in 2018, AxLabs has developed neow3j, an SDK and compiler for Java/Kotlin, which has been adopted by prominent entities like Binance and Flamingo FUSD. Additionally, AxLabs maintains GrantShares DAO, a decentralized grant program for the Neo ecosystem. The team is comprised of passionate builders with a track record in the blockchain industry.`,
        website_url:"https://axlabs.com",
        socials:{
            "twitter": {
                url:"https://twitter.com/ax_labs",
            },
            "discord":{
                url:"https://discord.axlabs.com"
            },
            "telegram":{
                url:"https://t.me/axlabs"
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:""
            },
            "github":{
                url:"https://github.com/AxLabs"
            },
            "medium":{
                url:"https://medium.com/@axlabs"
            },
        }
    },
    "Perlin": {
        id: "Perlin",
        slug:"perlin",
        name: "Perlin",
        icon_url:"/assets/logos/perlin.png",
        short_description:"Cutting-edge decentralized money market.",
        category:["DeFi", "Tokenomics"],
        description:`Perlin is a state-of-the-art decentralized money market protocol. Capitalizing on DeFi advancements, Perlin enables users to effortlessly lend, borrow, and earn with unmatched security and transparency.`,
        website_url:"https://perlin.finance",
        socials:{
            "twitter": {
                url:"https://twitter.com/perlinfinance",
            },
            "discord":{
                url:"https://discord.gg/ta3QevGV"
            },
            "telegram":{
                url:""
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:""
            },
            "github":{
                url:""
            },
            "medium":{
                url:"https://medium.com/@digination"
            },
        }
    },
    "DigiFun": {
        id: "DigiFun",
        slug:"digi",
        name: "DIGI",
        icon_url:"/assets/logos/digi.png",
        short_description:"A metaverse for you to build, play and connect.",
        category:["Metaverse"],
        description:`Digi combines metaverse building, gaming, and social interactions through Web3 technologies, facilitating a smooth transition for users from Web2 to Web3. Built on the pillars of transparency, security, and interoperability, the platform strives to offer users an array of captivating experiences for interaction and communication within the metaverse.`,
        website_url:"https://digi.fun",
        socials:{
            "twitter": {
                url:"https://twitter.com/Digi_Metaverse",
            },
            "discord":{
                url:"https://discord.gg/digination"
            },
            "telegram":{
                url:""
            },
            "youtube":{
                url:""
            },
            "reddit":{
                url:""
            },
            "github":{
                url:""
            },
            "medium":{
                url:"https://medium.com/@digination"
            },
        }
    },

}

const AirTableKey = "patY6EO4dEYpKlggs.2cc306a45047541747012987553802e379e39dfa59b24b82691492280f4c177b"

const Global = {
    SocialIcons,
    Projects,
    AirTableKey
}
export default Global;