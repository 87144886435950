export default function GreenLoadingIndicator(props) {
    return (
        <svg className='w-full h-full animate-spin' viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="203.5" cy="203.5" r="141.907" transform="rotate(-45.7055 203.5 203.5)" stroke="url(#paint0_linear_565_3373)" strokeWidth="4" />
        <circle cx="186.015" cy="62.0309" r="6.36054" transform="rotate(-45.7055 186.015 62.0309)" fill="#00E599" />
        <defs>
            <linearGradient id="paint0_linear_565_3373" x1="260.745" y1="230.93" x2="259.95" y2="86.2276" gradientUnits="userSpaceOnUse">
                <stop stopColor="#00E599" />
                <stop offset="1" stopColor="#00E599" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
    )
}