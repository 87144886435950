import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TopNavLogo from '../../Components/TopNavLogo'
import ScanInstruction from '../../assets/img/ring-scan-instruction.png'
import WalletConnectConnectedIcon from '../../Components/WalletConnectConnectedIcon'
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import GreenLoadingIndicator from '../../Components/GreenLoadingIndicator'
import { CheckIcon } from '@heroicons/react/24/outline';
import { useEventContext } from "../../contexts/EventContext";
import { useBindingAppContext } from '../../contexts/BindingAppContext';
import Global from '../../Global';
export default function ScanToBind(props) {

    const eventContext = useEventContext()
    const navigate = useNavigate()
    const wcSdk = useWalletConnect()

    const [searchParams, setSearchParams] = useSearchParams();

    const [startBinding, setStartBinding] = React.useState(false)
    const [bindingCompleted, setBindingCompleted] = React.useState(false)

    // This is the end state from the android app to tell this screen to fetch info about the ring. e.g. nft image 
    const [signature, setSignature] = React.useState(searchParams.get("signature"))
    const [publickey, setPublickey] = React.useState(searchParams.get("publickey"))
    const [blockIndex, setBlockIndex] = React.useState(searchParams.get("blockindex"))
    const [blockHash, setBlockHash] = React.useState(searchParams.get("blockhash"))

    const [lizardTokenId, setLizardTokenId] = React.useState(searchParams.get("lizardtokenid"))
    const [itemTokenId, setItemTokenId] = React.useState(searchParams.get("itemtokenid"))

    const [lizardData, setLizardData] = React.useState(null)
    const [itemData, setItemData] = React.useState(null)

    const address = wcSdk && wcSdk.getAccountAddress()

    const bindingAppContext = useBindingAppContext()

    const getData = async (lizardTokenId, itemTokenId) => {
        const [item, lizard] = await Promise.all([
            await bindingAppContext.getItemJSON(itemTokenId),
            await bindingAppContext.getLizardJSON(lizardTokenId)
        ]);
        setItemData(item)
        setLizardData(lizard)
    }

    const getRingImageUrlWithColor = (color) => {
        return `https://props.coz.io/img/item/neo/2780587208/oneBand_${color}.png`
    }


    const refresh = () => {
        if (lizardTokenId && itemTokenId) {
            getData(lizardTokenId, itemTokenId)
        }
    }

    React.useEffect(() => {
        if (lizardTokenId && itemTokenId) {
            getData(lizardTokenId, itemTokenId)
        }
    }, [lizardTokenId, itemTokenId])

    const process = async (address, tokenId, signature, publickey, blockIndex, blockHash) => {
        const response = await bindingAppContext.bindItemConfirmed(tokenId, publickey, blockIndex, signature)
        if (response == true) {
            setBindingCompleted(true)
            navigate(eventContext.getFullPathURL(`/binding/scan/completed?signature=${signature}&publickey=${publickey}&address=${address}&blockindex=${blockIndex}&blockhash=${blockHash}`))
        } else {
            alert(`Something went wrong when trying to binding ${tokenId} to ${address}`)
        }
    }

    React.useEffect(() => {
        setStartBinding(true)
        if (wcSdk.getAccountAddress() && bindingAppContext.account && itemTokenId && signature && publickey && blockIndex && blockHash) {
            process(wcSdk.getAccountAddress(), itemTokenId, signature, publickey, blockIndex, blockHash)
        } else {
            setStartBinding(false)
        }    
    }, [wcSdk.getAccountAddress(), bindingAppContext.account, itemTokenId, signature, publickey, blockIndex, blockHash])


    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">

            <WalletConnectConnectedIcon />
            <div className="w-full  fixed top-0  py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>
            <div className='container  mx-auto h-full w-full flex flex-col items-center justify-center text-white'>

                <p className='text-xl font-bold text-white mb-3'>Congratulations on receiving your assets.</p>
                <p className='text-white'>Collect your OneBand from the item shop staff and tap scan to bind it to your ITEM NFT </p>

                <div className='w-full flex items-center justify-between mt-10 max-w-4xl'>
                    <div className='flex flex-col gap-4 items-center w-full'>
                        <div onClick={(e)=>{refresh()}} className='card w-full aspect-square overflow-hidden flex items-center justify-center'>
                            {
                                lizardData == null ?
                                    <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    :
                                    <img src={lizardData && lizardData.image} className='w-full object-contain' />
                            }
                        </div>
                        <p className='text-sm font-bold text-primary uppercase'>LIZARD NFT</p>
                    </div>
                    <div className='relative flex flex-col items-center justify-center w-full h-full'>

                        <div className='w-full flex items-center justify-between'>
                            <div className='px-2 flex items-center justify-center'>
                                <svg className='h-4' viewBox="0 0 94 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 20.2783L8.65317 11.5L0 2.7025L2.66397 0L14 11.5L2.66397 23L0 20.2783Z" fill="#00E599" />
                                    <path d="M20 20.2783L28.6532 11.5L20 2.7025L22.664 0L34 11.5L22.664 23L20 20.2783Z" fill="#00E599" />
                                    <path d="M40 20.2783L48.6532 11.5L40 2.7025L42.664 0L54 11.5L42.664 23L40 20.2783Z" fill="#00E599" />
                                    <path d="M60 20.2783L68.6532 11.5L60 2.7025L62.664 0L74 11.5L62.664 23L60 20.2783Z" fill="#00E599" />
                                    <path d="M80 20.2783L88.6532 11.5L80 2.7025L82.664 0L94 11.5L82.664 23L80 20.2783Z" fill="#00E599" />
                                </svg>
                            </div>
                            <div className='w-full relative flex items-center justify-center'>

                                <div className='relative bg-card rounded-full ring-1 ring-primary w-28 h-28 drop-shadow-2xl overflow-hidden flex items-center justify-center'>
                                    {
                                        startBinding === false && bindingCompleted === false ?
                                            <img src={ScanInstruction} className='w-full h-auto object-cover' />
                                            : null
                                    }
                                    {
                                        startBinding === true && bindingCompleted == false ?
                                            <div className=' w-28 h-28 flex items-center justify-center'>
                                                <GreenLoadingIndicator />
                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        bindingCompleted ?
                                            <CheckIcon className='w-20 h-20 text-primary' />
                                            : null
                                    }
                                </div>

                            </div>
                            <div className='px-2 items-center justify-center'>
                                <svg className='h-4' viewBox="0 0 94 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M94 2.72167L85.3468 11.5L94 20.2975L91.336 23L80 11.5L91.336 -2.32891e-07L94 2.72167Z" fill="#00E599" />
                                    <path d="M74 2.72167L65.3468 11.5L74 20.2975L71.336 23L60 11.5L71.336 -2.32891e-07L74 2.72167Z" fill="#00E599" />
                                    <path d="M54 2.72167L45.3468 11.5L54 20.2975L51.336 23L40 11.5L51.336 -2.32891e-07L54 2.72167Z" fill="#00E599" />
                                    <path d="M34 2.72167L25.3468 11.5L34 20.2975L31.336 23L20 11.5L31.336 -2.32891e-07L34 2.72167Z" fill="#00E599" />
                                    <path d="M14 2.72167L5.34683 11.5L14 20.2975L11.336 23L1.00536e-06 11.5L11.336 -2.32891e-07L14 2.72167Z" fill="#00E599" />
                                </svg>

                            </div>
                        </div>
                        <p className='absolute bottom-4 uppercase text-primary font-medium px-4 text-center'>
                            Place your OneBand on the scanner
                        </p>
                    </div>
                    <div className='flex flex-col gap-4 items-center w-full'>
                        <div onClick={(e)=>{refresh()}} className='card w-full aspect-square overflow-hidden flex items-center justify-center'>
                            {
                                itemData == null ?
                                    <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    :
                                    <img src={itemData && getRingImageUrlWithColor(itemData.traits[0].color)} className='w-full' />
                            }

                        </div>
                        <p className='text-sm font-bold text-primary uppercase'>ITEM NFT</p>
                    </div>
                </div>
            </div>
            <div className='absolute bottom-0 right-0 p-4'>
                <p className='text-xs text-white'>{wcSdk.getAccountAddress()}</p>
            </div>

        </div>
    )

}