import NeoLogo from '../assets/img/neo-logo.svg'
export default function TopNavLogo(props) {
    return (
        <div className="flex items-center gap-2">
            <div>
                <img src={NeoLogo} className="h-10 w-10" />
            </div>
            <div className="text-xs">
                <p className="uppercase tracking-widest text-primary font-bold leading-tight">C O N S E N S U S&nbsp;&nbsp;2 0 2 3</p>
                <p className="uppercase tracking-wide text-white font-semibold">T O U C H&nbsp;&nbsp;T H E&nbsp;&nbsp;W O R L D</p>
            </div>
        </div>
    )
}