import React from "react";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";

import ScanStationHome from "./ScanStationApp/ScanStationHome";
import { ScanStationAppContext, ScanStationAppContextProvider } from "./contexts/ScanStationAppContext";
export default function ScanStationApp() {

  const [searchParams, setSearchParams] = useSearchParams();
  const wif = searchParams.get("wif") || localStorage.getItem("_scanstation_wif")
  const projectId = searchParams.get("project") || localStorage.getItem("_scanstation_project")
  const navigate = useNavigate()  
  React.useEffect(()=>{
    if (!projectId || !wif) {
      navigate("./error",{replace: true})
    }
  },[])

  return (
    //start from /station
    <ScanStationAppContextProvider wif={wif} projectId={projectId}>
    <Routes> 
      <Route path="/" element={<ScanStationHome />}></Route>
      <Route path="/error" element={<ScanStationError />}></Route>
    </Routes>
    </ScanStationAppContextProvider>
  );
}




function ScanStationError() {
  return (
    <div className="h-full w-full text-white flex items-center justify-center">WIF and ProjectID are required</div>
  )
}