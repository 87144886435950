import * as React from 'react';
import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline";
import PrimaryButton from '../../Components/PrimaryButton';
import { useAccountContext } from "../../contexts/AccountContext";
import QRCode from 'react-qr-code';
export default function ExportKey(props) {
    const { isPresented, onClose, onApproved, onRejected } = props

    const accountContext = useAccountContext()
    const account = accountContext.account

 
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    }


    return (
        <Transition show={isPresented} as={Fragment}>
            <Dialog onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/80" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500 transition transform"
                    enterFrom="opacity-100 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-200 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-full"
                >
                    <Dialog.Panel className="h-9/10 max-h-9/10 z-[100] bg-[#1F2B30] fixed bottom-0 left-0 w-full  rounded-t-2xl drop-shadow-lg flex flex-col overflow-hidden">

                        <div className='flex flex-col w-full h-full overflow-hidden'>

                            <div className="w-full flex-none p-3 bg-mid-blue-gray flex items-center justify-between">
                                <div className=" flex-none">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " disabled onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-transparent" /></button>
                                </div>
                                <div className="text-white font-bold w-full text-center">Export Private Key</div>
                                <div className="flex-none ml-auto">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-primary" /></button>
                                </div>
                            </div>

                            <div className='w-full h-full flex flex-col py-4 px-4 overflow-y-auto'>

                                <div className='ring-1 ring-primary rounded-xl p-4 w-full'>
                                    <div className='bg-white p-4 rounded-xl'>
                                        {
                                            account ? 
                                            <QRCode className='w-full' value={account.address} />
                                            : null
                                        }
                                        
                                    </div>

                                    <div className="mt-4 card w-full flex p-4 rounded-xl breakall text-white break-all">

                                        <div className="flex flex-col">
                                            <div className="flex items-center mb-1">
                                                <p className="font-bold text-xs text-primary uppercase">YOUR PRIVATE KEY</p>
                                            </div>
                                            <p className="text-text font-semibold flex items-center">
                                                {account && account._WIF}
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='mt-8 w-full'>
                                    <PrimaryButton onClick={(e)=>{copyToClipboard(account._WIF)}}>Copy to clipboard</PrimaryButton>
                                </div>

                                <a href="https://neo.org/neogas#wallets" target='_blank' className='mt-8 w-full card flex items-center p-4 gap-4'>
                                    <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 7.525V36.2992L18.705 43V13.975L38.8792 6.5575L20.5683 0L0 7.525Z" fill="#00E599" />
                                        <path d="M20.21 15.0501V30.5659L38.8791 37.2309V8.13426L20.21 15.0501Z" fill="#00AF92" />
                                    </svg>

                                    <div>
                                        <p className='text-white font-bold text-lg leading-none'>Need a wallet of your own?</p>
                                        <p className='text-white'>Download a wallet at Neo.org</p>
                                    </div>
                                    <div className='ml-auto'>
                                        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M22 9.52941H15.7143V0H6.28571V9.52941H0L11 20.6471L22 9.52941ZM0 23.8235V27H22V23.8235H0Z" fill="#00E599" />
                                        </svg>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}