import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import ScanYourring from '../../assets/img/scan-your-ring.png'
import TopNavLogo from '../../Components/TopNavLogo'
import GreenLoadingIndicator from '../../Components/GreenLoadingIndicator';
import react from '@heroicons/react';
import Global from '../../Global';
import { useScanStationAppContext } from "../../contexts/ScanStationAppContext";


export function ErrorState() {
    return (<div className='flex flex-col items-center'>
        <p className={`text-xl font-bold text-[#FF005C]`}>Sorry something went wrong!</p>
        <p className={`text-lg mb-8 text-[#FF005C]`}>Please try holding your OneBand closer to the scanner</p>
        <div className='w-80 h-80 bg-card rounded-full flex flex-col items-center justify-center'>
            <svg className='w-48 h-48' viewBox="0 0 364 364" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="182" cy="182" r="177.5" stroke="#FF005C" strokeWidth="9" />
                <line x1="273.931" y1="98.7652" x2="100.765" y2="271.932" stroke="#FF005C" strokeWidth="9" />
                <line x1="267.568" y1="271.932" x2="94.401" y2="98.7652" stroke="#FF005C" strokeWidth="9" />
            </svg>
        </div>
    </div>)
}

export function AlreadyScannedState() {
    return (<div className='flex flex-col items-center'>
        <p className={`text-xl font-bold text-[#FF8A00]`}>You have already scanned this booth!</p>
        <p className={`text-lg mb-8 text-[#FF8A00]`}>Check your app for unscanned booths</p>
        <div className='w-80 h-80 bg-card rounded-full flex flex-col items-center justify-center'>
            <svg className='w-49 h-48' viewBox="0 0 364 364" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="182" cy="182" r="177.5" stroke="#FF8A00" strokeWidth="9" />
                <line x1="320.333" y1="186.833" x2="48.333" y2="186.833" stroke="#FF8A00" strokeWidth="9" />
            </svg>

        </div>
    </div>)
}

export function ScanningState() {
    return (
        <div className='flex flex-col items-center'>
            <p className='text-xl font-bold'>Scan your OneBand</p>
            <p className='text-lg mb-8'>Hold your OneBand close to the reader on the table.</p>
            <div className='w-80 h-80 bg-card rounded-full flex flex-col items-center justify-center'>
                <div className='w-40 h-40 mb-4'>
                    <GreenLoadingIndicator />
                </div>
                <p className='text-primary text-xl font-bold'>Scanning...</p>
            </div>
        </div>
    )
}

export function StartState() {
    return (
        <div className='flex flex-col items-center'>
            <p className='text-xl font-bold'>Scan your OneBand</p>
            <p className='text-lg mb-8'>Hold your OneBand close to the reader on the table.</p>
            <img src={ScanYourring} className="w-80 h-80" />
        </div>
    )
}

export function SuccessState(props) {
    const { lizardData } = props
    return (
        <div className='flex flex-col items-center'>
            <p className='text-xl font-bold text-primary'>Scan successful!</p>
            <p className='text-lg mb-8 text-primary'>Your LIZARD NFT has been updated!</p>
            <div className='w-80 h-80 bg-card rounded-full flex flex-col items-center justify-center overflow-hidden'>
                {
                    lizardData == null ?
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-20 h-20 text-primary">
                        <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd" />
                    </svg>
                    
                        :
                        <img src={lizardData && lizardData.image} className='w-full object-contain' />
                }
            </div>
        </div>
    )
}

export default function ScanStationHome(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [state, setState] = React.useState(searchParams.get("state"))

    const [signature, setSignature] = React.useState(searchParams.get("signature"))
    const [publicKey, setPublicKey] = React.useState(searchParams.get("publickey"))
    const [blockIndex, setBlockIndex] = React.useState(searchParams.get("blockindex"))
    const [blockHash, setBlockHash] = React.useState(searchParams.get("blockhash"))

    const [lizardData, setLizardData] = React.useState(null)
    const scanStationAppContext = useScanStationAppContext()

    const ScanStates = {
        Start: "start",
        Scanning: "scanning",
        Error: "error",
        AlreadyScanned: "already-scan",
        Success: "success"
    }

    const [currentState, setCurrentState] = React.useState(ScanStates.Start)

    async function addRecord(_project, _publicKey, _signature, _blockIndex, _blockHash) {

        const url = 'https://api.airtable.com/v0/appB2rIPDLzAmgJx3/scan';
        const token = Global.AirTableKey;
        const data = {
            records: [
                {
                    fields: {
                        "project": _project,
                        "publicKey": _publicKey,
                        "signature": _signature,
                        "blockIndex": _blockIndex,
                        "blockHash": _blockHash,
                    }
                },
            ],
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            const result = await response.json();
            // console.log(result);
        } else {
            console.log("error");
        }
    }

    const confirmScanned = async (publickey, blockIndex, signature) => {
        const response = await scanStationAppContext.confirmScan(publickey, blockIndex, signature)
        return response
    }

    const checkScanStation = async (projectId, publicKey) => {
        const data = await scanStationAppContext.getLizardWithItemWithPublicKey(publicKey)
        const found = data.waypoints.filter((p) => { return p.waypoint === projectId })
        if (found) {
            const project = found[0]
            return project.state
        }

        return false
    }

    const process = async (projectId, publicKey, signature, blockIndex, blockHash) => {
        // const alreadyScanned = await checkScanStation(projectId, publicKey)

        try {
            const result = await confirmScanned(publicKey, blockIndex, signature)
            //fetch lizard data again to get updated image
            // const data = await scanStationAppContext.getLizardWithItemWithPublicKey(publicKey)
            // setLizardData(data);
            //Add record to airtable
            console.log("result confirmScanned ", result)
            addRecord(projectId, publicKey, signature, blockIndex, blockHash)
            setCurrentState(ScanStates.Success)
        } catch (error) {
            setCurrentState(ScanStates.AlreadyScanned)
        }
        // if (alreadyScanned) {
            
        // } else {
        //     //confirm scan
           
        // }
    }

    React.useEffect(() => {
        
        if (scanStationAppContext.project && signature && publicKey && blockIndex && blockHash) {
            setCurrentState(ScanStates.Scanning)
            process(scanStationAppContext.project.id, publicKey, signature, blockIndex, blockHash)
        }
    }, [scanStationAppContext.project, signature, publicKey, blockIndex, blockHash])

    React.useEffect(() => {
        if (state) {
            if (state === "scanning") {
                setCurrentState(ScanStates.Scanning)
            } else if (state === "success" && publicKey && signature) {
                setCurrentState(ScanStates.Success)
            } else if (state === "error") {
                setCurrentState(ScanStates.Error)
            } else if (state === "alreadyscanned") {
                setCurrentState(ScanStates.AlreadyScanned)
            } else {
                setCurrentState(ScanStates.Start)
            }
        }
    }, [state, publicKey, signature])


    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">
            <div className="container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>


            <div className='h-full w-full flex flex-col items-center justify-center text-white'>
                {
                    currentState === ScanStates.Start ?
                        <StartState />
                        :
                        currentState === ScanStates.AlreadyScanned ?
                            <AlreadyScannedState />
                            :
                            currentState === ScanStates.Error ?
                                <ErrorState />
                                :
                                currentState === ScanStates.Scanning ?
                                    <ScanningState />
                                    :
                                    currentState === ScanStates.Success ?
                                        <SuccessState lizardData={lizardData} />
                                        :
                                        null
                }
            </div>

            <div className='absolute bottom-4 right-4 p-4 text-right text-white'>
                {/* <div className='flex items-center gap-3'>
                    <button onClick={(e) => { simulateScan(ScanStates.Success) }} className='text-xs px-2 py-1 border border-white mb-4'>Simulate success</button>
                    <button onClick={(e) => { simulateScan(ScanStates.Error) }} className='text-xs px-2 py-1 border border-white mb-4'>Simulate error</button>
                    <button onClick={(e) => { simulateScan(ScanStates.AlreadyScanned) }} className='text-xs px-2 py-1 border border-white mb-4'>Simulate already scanned</button>
                </div> */}

                <p className='text-xs font-medium'>Project</p>
                <p className='text-sm font-semibold'>{scanStationAppContext.project && scanStationAppContext.project.id}</p>
                <p className='text-xs'>{scanStationAppContext.account && scanStationAppContext.account._address}</p>
            </div>

            <div className='absolute top-4 right-4  text-right text-white'>
                {/* <button onClick={(e) => { reset() }} className='text-xs px-2 py-1 border border-white mb-4'>Reset</button> */}
            </div>
        </div>
    )

}