import * as React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import UnsupportedEvent from "./UnsupportedEvent"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename="">
    <Routes>
      <Route path="/eh" element={<UnsupportedEvent />}></Route>
      <Route path="/:eventId/*" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
