import * as React from 'react';
import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline";
import Banner from '../../assets/img/lizard_loot_banner.png'
export default function LizardLootDialog(props) {
    const { isPresented, onClose } = props
    return (
        <Transition show={isPresented} as={Fragment}>
            <Dialog onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/80" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500 transition transform"
                    enterFrom="opacity-100 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-200 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-full"
                >
                    <Dialog.Panel className="h-full max-h-full z-[100] bg-[#1F2B30] fixed bottom-0 left-0 w-full drop-shadow-lg flex flex-col overflow-hidden">
                        <div className='max-w-sm mx-auto overflow-y-auto'>
                            <div className='bg-[#050C0F] flex flex-col items-center justify-center p-4 rounded-b-xl'>
                                <div className='w-full flex items-center justify-end'>
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-white" /></button>
                                </div>
                                <p className='text-xl font-bold text-white'>Lizard Loot</p>
                            </div>

                            <div className='p-4'>
                                <img src={Banner} className='mb-4 ' />

                                <p className='text-white'>
                                    Get ready for Lizard Loot, your chance to win token prizes from a $6,000+ prize pool!
                                    <br />
                                    <br />
                                    As a special bonus for participating in Neo's Consensus 2023 activation, all LIZARD NFT token holders can use their Lizards to unlock exclusive chests and win Neo ecosystem token prizes!
                                    <br /><br />
                                    Lizard Loot will be available after the Consensus 2023 event. The details are as follows:
                                    <br /><br />
                                    Each LIZARD NFT can open up to three chests:
                                    <br /><br />
                                    <ul className='list-disc px-4'>
                                    <li>Basic Chest: Win up to 5 GAS. Any LIZARD NFT can loot a Basic Chest.</li>
                                    <li>Superior Chest: Win up to 10 GAS. LIZARD NFTs with more than five traits collected at Consensus 2023 can loot a Superior Chest.</li>
                                    <li>Elite Chest: Win up to 100 GAS. LIZARD NFTs with a rare trait collected at Consensus 2023 can loot an Elite Chest.</li>
                                    </ul>
                                    <br/>
                                    Rare traits are denoted in your Consensus 2023 Neo wallet in gold.
                                    <br /><br />
                                    Maximize your chances to win by visiting all the Neo pods at Consensus 2023 to collect as many traits as possible!
                                </p>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}