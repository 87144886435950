import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TopNavLogo from '../../Components/TopNavLogo'
import WalletConnectConnectedIcon from '../../Components/WalletConnectConnectedIcon'
import LoadingGreen from '../../assets/img/loading-green.png'
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { CheckIcon } from '@heroicons/react/24/outline';
import { useEventContext } from "../../contexts/EventContext";
import { useBindingAppContext } from '../../contexts/BindingAppContext';
import { useAccountContext } from '../../contexts/AccountContext';
export default function ClaimingInProgress(props) {

    const eventContext = useEventContext()
    const navigate = useNavigate()
    const wcSdk = useWalletConnect()
    const [claimCompleted, setClaimCompleted] = React.useState(false)
    const bindAppContext = useBindingAppContext()
    

    const mintAssetsToAddress = async (address) => {
        const response = await bindAppContext.mintToAddress(address) 
        setTimeout(() => {
            setClaimCompleted(true)
            setTimeout(() => {
                let path = `/binding/scan?lizardtokenid=${response.lizardTokenId}&itemtokenid=${response.itemTokenId}&address=${address}`
                navigate(eventContext.getFullPathURL(path))
            }, 1000);
        }, 1000);
    }

    React.useEffect(()=>{
        if (wcSdk.getAccountAddress() && bindAppContext.account) {
            let address = wcSdk.getAccountAddress()
            mintAssetsToAddress(address)
        }
    },[wcSdk.getAccountAddress(), bindAppContext.account])

    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">

        <WalletConnectConnectedIcon/>

        <div className="container mx-auto py-5 flex items-center justify-center">
            <TopNavLogo />
        </div>
        <div className='h-full w-full flex flex-col items-center justify-center text-white '>

            <p className='text-xl font-bold text-white mb-10'>Asset claim in progress</p>
           
           <div className='container mx-auto flex items-center justify-center gap-20'>
                <div className='flex flex-col items-center gap-10'>
                    <div className='card w-48 h-48 flex items-center justify-center'>
                        {
                            claimCompleted ? 
                            <CheckIcon className='w-20 h-20 text-primary'/>
                            :
                            <img src={LoadingGreen} className="w-24 animate-spin" />
                        }
                    </div>
                    <p className='font-semibold text-primary text-sm uppercase'>GAS TOKENS</p>
                </div>
                 <div className='flex flex-col items-center gap-10'>
                 <div className='card w-48 h-48 flex items-center justify-center'>
                 {
                            claimCompleted ? 
                            <CheckIcon className='w-20 h-20 text-primary'/>
                            :
                            <img src={LoadingGreen} className="w-24 animate-spin" />
                        }
                    </div>
                    <p className='font-semibold text-primary text-sm uppercase'>LIZARD NFT</p>
                </div>
                <div className='flex flex-col items-center gap-10'>
                <div className='card w-48 h-48 flex items-center justify-center'>
                {
                            claimCompleted ? 
                            <CheckIcon className='w-20 h-20 text-primary'/>
                            :
                            <img src={LoadingGreen} className="w-24 animate-spin" />
                        }
                    </div>
                    <p className='font-semibold text-primary text-sm uppercase'>ITEM NFT</p>
                </div>
           </div>
           
        </div>

    </div>
    )

}