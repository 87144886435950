import React from "react";
import PropTypes from 'prop-types';
import { default as Neon, wallet } from "@cityofzion/neon-js";
import { Item } from '@cityofzion/props-item'
import { Lizard } from '@cityofzion/nft-lizard'
import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NeonParser } from '@cityofzion/neon-parser'

export const AccountContext = React.createContext({});
export const AccountContextProvider = ({ wif, children }) => {

    const NODE = "https://mainnet1.neo.coz.io:443"
    const [account, setAccount] = React.useState(null);
    const init = React.useCallback(async () => {

        if (wif === undefined || wif === null) {
            setAccount(null)
            return
        }

        const isWif = Neon.is.wif(wif);
        if (isWif) {
            const neoAccount = new wallet.Account(wif)
            setAccount(neoAccount)
            localStorage.setItem("_consensus2023_wif", wif)
        }
    }, []);

    const initItem = async () => {
        const item = new Item({
            scriptHash: Item.MAINNET,
            invoker: await NeonInvoker.init(NODE, account),
            parser: NeonParser,
        })
        return item
    }

    const initLizard = async () => {
        const lizrd = new Lizard({
            scriptHash: Lizard.MAINNET,
            invoker: await NeonInvoker.init(NODE, account),
            parser: NeonParser,
        })
        return lizrd
    }

    const getItemData = async (tokenId) => {
        const item = await initItem()
        const res = item.getItemJSON(
            {
                tokenId: tokenId.toString()
            })
        return res
    }

    const getLizardData = async (tokenId) => {
        const lizard = await initLizard()
        const res = lizard.getLizardJSON(
            {
                tokenId: tokenId.toString()
            })
        return res
    }

    const itemTokensOf = async () => {
        const itemSDK = await initItem()
        return itemSDK.tokensOf({address: account._address})
    }

    const lizardTokensOf = async () => {
        const lizardSDK = await initLizard()
        return lizardSDK.tokensOf({address: account._address})
    }

    const fetchBalance = async () => {
        
        const lizardSDK = await initLizard()
        const itemSDK = await initItem()

        const [items, lizard] = await Promise.all([
            await itemSDK.tokensOf({address: account._address}),
            await lizardSDK.tokensOf({address: account._address})
        ])

        var balances = []
        if (items.length > 0){
            const tokenId = items[0]
            const data = await getItemData(tokenId)
            if (data) {
                balances.push(data)
            }
        }

        if (lizard.length > 0){
            const tokenId = lizard[0]
            const data = await getLizardData(tokenId)
            if (data) {
                balances.push(data)
            }
        }
        
        return balances
    }

    React.useEffect(() => {
        init()
    }, [])

    const contextValue = {
        account,
        fetchBalance,
        getItemData,
        getLizardData,
        itemTokensOf,
        lizardTokensOf
    };

    return (React.createElement(AccountContext.Provider, { value: contextValue }, children));
};

export const useAccountContext = () => React.useContext(AccountContext);
AccountContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};