import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import TopNavLogo from '../../Components/TopNavLogo'
import TouchToBegin from '../../assets/img/touch-to-begin.png'
import ConnectionIcon from '../../assets/img/connection-icon.png'
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import QRCode from "react-qr-code";
import { useEventContext } from "../../contexts/EventContext";
import { useBindingAppContext } from '../../contexts/BindingAppContext';
// polyfill Buffer for client
if (!window.Buffer) {
    window.Buffer = Buffer;
}

export default function BindingHome(props) {

    const eventContext = useEventContext()
    const navigate = useNavigate()
    const [startWalletConnect, setStartWalletConnect] = React.useState(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const NEW_URI_TIMEOUT_IN_SECONDS = searchParams.get("timeout") ?? 60 //20s to get new URI if not connected

    var wcSdk = useWalletConnect()
    const [walletConnectURI, setWalletConnectURI] = React.useState(null)
    const [newURITimeoutSecond, setNewURITimeoutSecond] = React.useState(NEW_URI_TIMEOUT_IN_SECONDS)
    const [newURIInterval, setNewURIInterval] = React.useState(null)
    const bindingAppContext = useBindingAppContext()


    const onTouchToBegin = () => {
        if (!bindingAppContext.account) {
            alert("No account provided")
            return
        }
        setStartWalletConnect(true)
    }

    const reset = () => {
        setStartWalletConnect(false)
        disconnect()
    }

    const disconnect = async () => {
        clearInterval(newURIInterval)
        setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS)
        setWalletConnectURI(null)

        if (wcSdk.session === undefined) {
            console.log("wcSdk.session is undefined")
            return
        }
        await wcSdk.disconnect()
    }

    React.useEffect(() => {

        if (startWalletConnect == true && wcSdk.isConnected() === false && walletConnectURI !== null) {

            //generate new URI every 20s to prevent stale connection
            if (newURIInterval) {
                clearInterval(newURIInterval)
            }

            const interval = setInterval(() => {
                setNewURITimeoutSecond(newURITimeoutSecond => newURITimeoutSecond - 1)
            }, 1000);

            setNewURIInterval(interval)

            return () => clearInterval(interval);

        }
    }, [startWalletConnect, wcSdk.session, walletConnectURI])


    const connectWallet = async () => {

        if (wcSdk.signClient !== undefined && wcSdk.isConnected() === false && walletConnectURI == null) {
            const { uri, approval } = await wcSdk.createConnection('neo3:mainnet')
            console.log(uri);
            setWalletConnectURI(uri)
            const session = await approval()
            console.log("session", session);
            wcSdk.setSession(session)
        }
    }

    //when timeout
    React.useEffect(() => {
        if (newURITimeoutSecond < 0) {
            if (wcSdk.session === undefined) {
                console.log("timeout — reset and connect wallet again")
                //stop count down interval
                clearInterval(newURIInterval)
                //reset counting down seconds
                setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS)
                //reset wallet connect URI
                setWalletConnectURI(null)
            }
        }
    }, [newURITimeoutSecond])

    React.useEffect(() => {

        if (startWalletConnect === false) {
            return;
        }

        if (wcSdk.signClient !== undefined && wcSdk.isConnected() === false && walletConnectURI == null) {
            console.log("connecting...")
            // setConnecting(true)
            clearInterval(newURIInterval)
            setNewURITimeoutSecond(NEW_URI_TIMEOUT_IN_SECONDS)
            connectWallet()
        }
    }, [bindingAppContext.account, wcSdk, walletConnectURI, startWalletConnect])

    const checkAndProcess = async (address) => {
        
        const balance = await bindingAppContext.getTokensOf(address)
        console.log("balance", balance)
        if (balance && balance.length > 0) {
            const itemData = balance.find((i) => { return i.name === "ITEM" })
            const lizardData = balance.find((i) => { return i.name === "LIZARD" })
            if (itemData && lizardData && itemData.asset === undefined) {
                let path = `/binding/scan?lizardtokenid=${lizardData.tokenId}&itemtokenid=${itemData.tokenId}&address=${address}`
                navigate(eventContext.getFullPathURL(path))
            }
        } else {
            let path = eventContext.getFullPathURL("/binding/signup")
            navigate(path, { replace: true })
        }
        //TODO: check if this user already has asset but doesn't have ring binding
        //if so we then redirect them to /collect/binding/scan


    }
    React.useEffect(() => {
        const isConnected = wcSdk.isConnected()
        if (isConnected == true) {
            setWalletConnectURI(null)
            let address = wcSdk.getAccountAddress()
            console.log("connected = ", address)
            checkAndProcess(address)
        }
    }, [wcSdk.isConnected()])

    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">
            <div className="container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>
            <div className='h-full w-full flex flex-col items-center justify-center'>

                {
                    startWalletConnect == false ?
                        <button onClick={() => onTouchToBegin()} className="flex flex-col items-center justify-center ">
                            <img src={TouchToBegin} className="h-96" />
                        </button>
                        :

                        <div className=" flex flex-col items-center justify-center bg-card aspect-square p-20 pt-4  rounded-full">

                            <img src={ConnectionIcon} className="h-10 mb-4" />

                            <p className="text-primary uppercase font-semibold tracking-widest mb-4 text-sm">SCAN ME WITH YOUR PHONE</p>
                            <div className="ring-2 ring-primary rounded p-4 mb-8">
                                <div className="bg-white p-4 w-44 h-44 flex items-center justify-center">
                                    {
                                        walletConnectURI ?
                                            <QRCode size={320} value={walletConnectURI}></QRCode>
                                            :
                                            null
                                    }


                                </div>
                            </div>
                            <div className=" text-center break-words max-w-md">
                                <p className="text-xl font-bold text-white mb-4">Connect your wallet to receive your assets.</p>
                                <p className="text-white mb-4">You will receive an ITEM NFT, LIZARD NFT, and GAS tokens.</p>
                                <p className="text-white">After claiming your assets, booth staff will provide your OneBand to bind to your ITEM NFT! </p>
                            </div>
                        </div>
                }

            </div>
            <div className='text-white text-xs flex flex-col gap-3 absolute bottom-0 left-0 p-4'>
                {walletConnectURI}
            </div>
            <div className='text-white flex flex-col gap-2 absolute bottom-0 right-0 p-4 text-xs'>
                <p>{newURITimeoutSecond}</p>
                <p>{bindingAppContext.account && bindingAppContext.account._address}</p>
                <button onClick={() => reset()} className='text-white'>Reset</button>
            </div>

        </div>
    )
}