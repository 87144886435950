import * as React from 'react';
import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline";
import PrimaryButton from '../../Components/PrimaryButton';
import GreenLoadingIndicator from '../../Components/GreenLoadingIndicator'
import { useAccountContext } from "../../contexts/AccountContext";
import SecondaryButton from '../../Components/SecondaryButton';

export function WithdrawInput(props) {
    const { onInput, value, onWithdrawTapped, disabled } = props
    return (<div className='flex w-full h-full flex-col items-center pt-8 py-4 px-4 overflow-y-auto'>

        <svg className='flex-none' width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.6183 32.9355C38.4918 32.9355 37.4115 33.3718 36.615 34.1484C35.8185 34.925 35.371 35.9783 35.371 37.0766V45.3589H39.6183V37.0766H56.6075V43.2883H51.2984L58.7312 50.5353L66.164 43.2883H60.8548V37.0766C60.8548 35.9783 60.4074 34.925 59.6108 34.1484C58.8143 33.3718 57.734 32.9355 56.6075 32.9355H39.6183ZM29 49.5V53.6411H45.9892V49.5H29ZM29 55.7117V59.8528H45.9892V55.7117H29ZM50.2366 55.7117V59.8528H67.2258V55.7117H50.2366ZM29 61.9234V66.0645H45.9892V61.9234H29ZM50.2366 61.9234V66.0645H67.2258V61.9234H50.2366Z" fill="white" />
            <path d="M46.25 1.87639C47.6423 1.07254 49.3577 1.07254 50.75 1.87639L87.7522 23.2396C89.1445 24.0435 90.0022 25.5291 90.0022 27.1368V69.8633C90.0022 71.4709 89.1445 72.9565 87.7522 73.7604L50.75 95.1236C49.3577 95.9275 47.6423 95.9275 46.25 95.1236L9.24777 73.7604C7.85546 72.9565 6.99777 71.4709 6.99777 69.8633V27.1367C6.99777 25.5291 7.85546 24.0435 9.24777 23.2396L46.25 1.87639Z" stroke="#00E599" />
        </svg>
        <p className='text-white mt-4'>You are about to withdraw all assets from this temporary wallet. Once withdrawn this wallet will be no longer of use.</p>
        <p className='text-white mt-4 mb-4'>If you don’t have a wallet don’t worry, a selection of compatible wallets can be found at Neo.org</p>
        <div className='w-24 h-1 bg-primary mb-4 flex-none'></div>
        <p className='font-bold text-white'>Please enter the address of your secure wallet you wish to withdraw all assets to.</p>
        <div className='mt-4 bg-card rounded flex items-center w-full overflow-hidden p-3 flex-none'>
            <input value={value} onChange={(e) => { onInput(e.target.value.trim()) }} type="text" className='w-full text-white bg-transparent appearance-none focus:outline-none focus:ring-0' placeholder='Tap here to paste address' />
            <button className='ml-auto pl-3'><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 8H2V10H0V8ZM8 2H10V6H8V2ZM6 8H10V12H8V10H6V8ZM12 8H14V10H16V8H18V10H16V12H18V16H16V18H14V16H10V18H8V14H12V12H14V10H12V8ZM16 16V12H14V16H16ZM12 0H18V6H12V0ZM14 2V4H16V2H14ZM0 0H6V6H0V0ZM2 2V4H4V2H2ZM0 12H6V18H0V12ZM2 14V16H4V14H2Z" fill="#00E599" />
            </svg>
            </button>
        </div>

        <div className='mt-8 w-full'>
            <PrimaryButton onClick={(e) => { onWithdrawTapped() }} disabled={disabled}>Withdraw</PrimaryButton>
        </div>
    </div>)
}

export default function WithdrawAssets(props) {
    const { isPresented, onClose, onApproved, onRejected } = props
    const accountContext = useAccountContext()
    const account = accountContext.account

    const [isLoading, setIsLoading] = React.useState(false)
    const [toAddress, setToAddress] = React.useState("")
    const [withdrawTxHash, setWithdrawTxHash] = React.useState(null)

    const startWithdraw = () => {
        setIsLoading(true)
        setTimeout(() => {
            setWithdrawTxHash("0xdd32cddd8aa00a7e9e8bfcafea902189184a4a7d3e70d52d8249f3cb4a0c542b")
            setIsLoading(false)
        }, 3000);
    }

    const isN3Address = () => {
        return toAddress.length == 34 && toAddress.startsWith("N")
    }

    const reset = () => {
        setToAddress("")
        setWithdrawTxHash(null)
        setIsLoading(false)
    }

    const Loading = () => {
        return (
            <div className='w-full h-full flex flex-col items-center justify-center p-8'>
                <div className='w-48 h-48 flex flex-col items-center justify-center mb-4'>
                    <GreenLoadingIndicator />
                    <svg className='-translate-y-4' width="173" height="19" viewBox="0 0 173 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.71" filter="url(#filter0_f_641_7806)">
                            <ellipse cx="86.324" cy="9.38188" rx="78.9373" ry="1.62041" fill="black" />
                        </g>
                        <defs>
                            <filter id="filter0_f_641_7806" x="0.386719" y="0.761467" width="171.875" height="17.2408" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="3.5" result="effect1_foregroundBlur_641_7806" />
                            </filter>
                        </defs>
                    </svg>

                </div>
                <p className='font-bold text-white mb-2'>Transaction Pending</p>
                <p className=' text-white'>When your transaction is successful, this screen will be updated.</p>
            </div>
        )
    }

    return (
        <Transition show={isPresented} as={Fragment}>
            <Dialog onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/80" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500 transition transform"
                    enterFrom="opacity-100 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-200 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-full"
                >
                    <Dialog.Panel className="h-9/10 max-h-9/10 z-[100] bg-[#1F2B30] fixed bottom-0 left-0 w-full  rounded-t-2xl drop-shadow-lg flex flex-col overflow-hidden">
                        <div className='flex flex-col w-full h-full overflow-hidden'>

                            <div className="p-3 bg-mid-blue-gray flex items-center justify-between">
                                <div className=" flex-none">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " disabled onClick={() => {reset();onClose()}}><XMarkIcon className="w-6 h-6 text-transparent" /></button>
                                </div>
                                <div className="text-white font-bold w-full text-center">Withdraw all Assets</div>
                                <div className="flex-none ml-auto">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " onClick={() =>  {reset();onClose()}}><XMarkIcon className="w-6 h-6 text-primary" /></button>
                                </div>
                            </div>

                            {
                                isLoading === false && withdrawTxHash === null ?
                                    <WithdrawInput value={toAddress}
                                        disabled={isN3Address() === false}
                                        onInput={(value) => { setToAddress(value) }}
                                        onWithdrawTapped={() => { startWithdraw() }}
                                    />
                                    : null
                            }

                            {
                                isLoading ?
                                    <Loading />
                                    :
                                    null
                            }

                            {
                                isLoading === false && withdrawTxHash !== null && toAddress !== "" ?

                                    <div className='flex flex-col items-center justify-center pt-8 py-4 px-4'>
                                        <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M50.75 1.87639L87.7522 23.2396C89.1445 24.0435 90.0022 25.5291 90.0022 27.1368V69.8633C90.0022 71.4709 89.1445 72.9565 87.7522 73.7604L50.75 95.1236C49.3577 95.9275 47.6423 95.9275 46.25 95.1236L9.24777 73.7604C7.85546 72.9565 6.99777 71.4709 6.99777 69.8633V27.1367C6.99777 25.5291 7.85546 24.0435 9.24777 23.2396L46.25 1.87639C47.6423 1.07254 49.3577 1.07254 50.75 1.87639Z" stroke="#00E599" />
                                            <path d="M42.1818 59.7761L34.5455 51.9403L32 54.5522L42.1818 65L64 42.6119L61.4545 40L42.1818 59.7761Z" fill="white" />
                                        </svg>

                                        <p className='font-bold text-white mt-4'>
                                            Assets successfully sent
                                        </p>
                                        <p className='text-white'>
                                            You assets have been successfully sent to your secure wallet.
                                        </p>

                                        <div className="mt-8 card w-full flex p-4 rounded-xl breakall text-white break-all">
                                            <div className="flex flex-col">
                                                <div className="flex items-center mb-1">
                                                    <p className="font-bold text-xs text-primary uppercase">FROM</p>
                                                </div>
                                                <p className="text-text font-semibold flex items-center">
                                                    {account && account.address}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24 12L21.885 9.885L13.5 18.255L13.5 -4.5897e-07L10.5 -5.90104e-07L10.5 18.255L2.115 9.885L1.38281e-06 12L12 24L24 12Z" fill="#00E599" />
                                            </svg>
                                        </div>

                                        <div className="mt-4 card w-full flex p-4 rounded-xl breakall text-white break-all">
                                            <div className="flex flex-col">
                                                <div className="flex items-center mb-1">
                                                    <p className="font-bold text-xs text-primary uppercase">TO</p>
                                                </div>
                                                <p className="text-text font-semibold flex items-center">
                                                    {toAddress}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='mt-8 w-full'>
                                            <a target='_blank' href={`https://dora.coz.io/block/neo3/mainnet/${withdrawTxHash}`}>
                                                <PrimaryButton>View transaction details</PrimaryButton>
                                            </a>
                                        </div>
                                    </div>
                                    : null
                            }


                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}