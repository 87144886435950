
import * as React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Global from '../../Global'

export default function ProjectDetail(props) {

    const navigate = useNavigate()
    const Projects = Global.Projects
    const SocialIcons = Global.SocialIcons;
    const {id} = useParams()

    const iconsByKey = {
        "id": <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.625V0H14.6667V1.625H8Z" fill="#00E599" />
            <path d="M8 4.0625V2.4375H14.6667V4.0625H8Z" fill="#00E599" />
            <path d="M10 6.5V4.875H16.6667V6.5H10Z" fill="#00E599" />
            <path d="M0 5V6.625H6.66667V5H0ZM0 7.4375V9.0625H6.66667V7.4375H0ZM8.33333 7.4375V9.0625H15V7.4375H8.33333ZM0 9.875V11.5H6.66667V9.875H0ZM8.33333 9.875V11.5H15V9.875H8.33333Z" fill="#00E599" />
        </svg>
        ,
        "web": <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12M12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM12.34 12H7.66C7.56 11.34 7.5 10.68 7.5 10C7.5 9.32 7.56 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM10 17.96C9.17 16.76 8.5 15.43 8.09 14H11.91C11.5 15.43 10.83 16.76 10 17.96ZM6 6H3.08C4.03 4.34 5.57 3.06 7.4 2.44C6.8 3.55 6.35 4.75 6 6ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57 16.93 4.03 15.65 3.08 14ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12M10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z" fill="#00E599" />
        </svg>
        ,
        "info": <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7H11V5H9M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#00E599" />
        </svg>
    }

    const icons = (key) => {
        let icon = iconsByKey[key]
        if (icon) {
            return icon
        }
        return <InformationCircleIcon className='w-5 h-5 text-primary' />
    }

    return (
        <main className='max-w-sm mx-auto flex flex-col'>

            <div className='bg-card rounded-none  w-full relative rounded-b-xl overflow-hidden'>                
                <div className='p-4 pb-0 flex items-center justify-end'>
                    <button onClick={(e) => { navigate(-1) }}><XMarkIcon className='w-6 h-6 text-white' /></button>
                </div>

                <div className='z-50 pb-4 px-4 flex items-center w-full gap-4'>
                    <img src={Projects[id].icon_url} className="w-12 h-12 object-cover" />
                    <div>
                        <p className='text-2xl font-black text-white'>{Projects[id].name}</p>
                    </div>
                </div>
            </div>
            <p className='text-white p-4'>
                {Projects[id].description}
            </p>

            <div className='px-4'>
                <div className='mt-3 w-full card p-4'>
                    <div className='flex items-center'>
                        <p className='font-black text-xl text-white'>Detail</p>
                    </div>
                    <div className='mt-4 flex flex-col w-full divide-y divide-white/10'>
                        <div className='flex items-center w-full gap-4 pt-2'>
                            <div className='flex-none'>{icons("web")}</div>
                            <p className='font-semibold text-white'>Website</p>
                            <p className='ml-auto text-primary truncate overflow-ellipsis'><a href={Projects[id].website_url} target="_blank">{Projects[id].website_url}</a></p>
                        </div>
                    </div>
                </div>
                <div className='mt-4 flex items-center gap-2 gap-y-2 flex-wrap justify-start mb-4'>
                    {
                        Object.keys(Projects[id].socials).map((key) => (
                            Projects[id].socials[key].url !== "" ?
                            <div key={`social-${key}`} className="bg-card text-white w-10 h-10 rounded-lg flex items-center justify-center">
                                <a target="_blank" href={Projects[id].socials[key].url}>
                                    <img src={SocialIcons[key]} className="w-6 h-6" />
                                </a>
                            </div>
                            : null
                        ))
                    }
                </div>
            </div>

        </main>

    )
}