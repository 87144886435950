import React from 'react'
import TopNavLogo from "../../Components/TopNavLogo";
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import RingBlack from "../../assets/img/ring_black.png"
import PrimaryButton from "../../Components/PrimaryButton"
import { useAccountContext } from "../../contexts/AccountContext";
import { useEventContext } from "../../contexts/EventContext";
import { Dialog, Transition } from '@headlessui/react'
import { useState, Fragment } from 'react'
import QRCodeSanner from '../Components/QRCodeScanner'
import { useWeb3WalletContext } from "../../contexts/Web3WalletContext";
import WalletConnectPermission from '../Components/WalletConnectPermission';

import { Core } from "@walletconnect/core";
import { Web3Wallet } from "@walletconnect/web3wallet";
import { DEFAULT_NAMESPACES } from '../../constants'
import SecondaryButton from '../../Components/SecondaryButton';
import AddressCard from '../Components/AddressCard';
import { Link, Navigate, useNavigate } from 'react-router-dom';

export default function UserHome(props) {

    const navigate = useNavigate()
    const accountContext = useAccountContext()
    const eventContext = useEventContext()
    const account = accountContext.account
    let [isQRCodeScannerPresented, setIsQRCodeScannerPresented] = React.useState(false)
    let [isWalletConnectPermissionPresented, setIsWalletConnectPermissionPresented] = React.useState(false)
    const web3WalletContext = useWeb3WalletContext()
    const isConnected = web3WalletContext.sessions.length > 0
    const [paring, setParing] = React.useState(false)

    const onScanned = async (scannedString) => {
        setIsQRCodeScannerPresented(false)
        let uri = scannedString
        console.log("uri = ", uri)
        setParing(true)
        console.log("pairing = ", uri)

        try {
            await web3WalletContext.pair(uri)
        } catch (e) {
            setParing(false)
        }
    }

    React.useEffect(() => {
        console.log("web3WalletContext.sessionProposals", web3WalletContext.sessionProposals)
        if (web3WalletContext.sessionProposals.length > 0) {
            setIsWalletConnectPermissionPresented(true)
        }
    }, [web3WalletContext.sessionProposals])

    const onApprovedWalletConnect = () => {
        setIsWalletConnectPermissionPresented(false)
        let approved = true
        const firstProposal = web3WalletContext.sessionProposals[0]
        handleSessionUserApproval(approved, firstProposal)
    }

    const onRejectedWalletConnect = () => {
        setIsWalletConnectPermissionPresented(false)
        let approved = false
        const firstProposal = web3WalletContext.sessionProposals[0]
        handleSessionUserApproval(approved, firstProposal)
    }

    const handleSessionUserApproval = async (approved, proposal) => {
        if (approved) {
            await web3WalletContext.approveProposal(proposal, [{
                address: account.address ?? '',
                chain: "neo3:mainnet"
            }], DEFAULT_NAMESPACES)
        } else {
            web3WalletContext.rejectProposal(proposal)
        }
    }

    React.useEffect(()=>{
        if (web3WalletContext.initialized === false) {
            var item = localStorage.getItem('_consensus2023_wif');
            localStorage.clear();
            localStorage.setItem('_consensus2023_wif', item);
        }
    }, [web3WalletContext.initialized])


    React.useEffect(() => {
        if (web3WalletContext.sessions.length == 0) {
            return
        }
        console.log("connected ", web3WalletContext)
        
        navigate(eventContext.getFullPathURL("/onboardinginprogress"))
    }, [web3WalletContext.sessions])


    const checkIfUserHasAlreadyBindedTheRing = async () => {
        const balance = await accountContext.fetchBalance()
        //look for Item Data and then check `asset` field if there is a value meaning that a user has already bind the ring
        const itemData = balance.find((i)=>{return i.name === "ITEM"})
        if (itemData) {
            console.log("this account has item: ",itemData)
            if (itemData.asset !== undefined) {
                navigate(eventContext.getFullPathURL("/wallet"))
            }
        }
    }

    React.useEffect(()=>{
        if (accountContext.account) {
            checkIfUserHasAlreadyBindedTheRing()
        }
    },[accountContext.account])

    if (account === null) {
        return (
            <main className='max-w-sm mx-auto flex flex-col'>
                <div className="bg-radial absolute top-0 left-0 w-full h-full z-[0]"></div>
                <div className="z-10 container mx-auto py-5 flex items-center justify-center">
                    <TopNavLogo />
                </div>

                <div className="z-10 container mx-auto w-full px-4 flex flex-col items-center justify-center">
                    <div className="card w-full h-full flex flex-col p-4 text-white rounded-lg overflow-hidden">
                        <p className="text-xl font-bold">Please visit Neo Booth</p>
                    </div>
                </div>
            </main>
        )
    };

    return (

        <main className='max-w-sm mx-auto flex flex-col'>

            <div className="bg-radial absolute top-0 left-0 w-full h-full"></div>

            <div className="z-10 container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>

            <div className="z-10 container mx-auto w-full  px-4 flex flex-col items-center justify-center">

                <div className="card w-full flex flex-col p-4 pt-16 rounded-lg overflow-hidden">

                    <img src={RingBlack} className="w-52 mx-auto -translate-x-4 mb-4" />

                    <div className="flex flex-col items-start w-full text-white">
                        <p className="font-bold text-sm text-primary uppercase">NEXT STEPS</p>
                        <p className="text-2xl font-bold mb-3">Now visit the item shop to collect your assets</p>
                        <p>Go to the item shop in the Neo booth to connect your wallet and claim your OneBand and token assets!</p>

                    </div>
                </div>

                <div className='mt-5 w-full'>
                    <AddressCard>
                        <div className='mt-5'>
                            {
                                isConnected ?
                                    <SecondaryButton onClick={(e) => { navigate(eventContext.getFullPathURL("/wallet"), { replace: true }) }}>View Wallet</SecondaryButton>
                                    :
                                    web3WalletContext.initialized ?
                                        <PrimaryButton onClick={(e) => { setIsQRCodeScannerPresented(true) }}>Connect Wallet</PrimaryButton>
                                        :
                                        <p>Loading</p>
                            }
                        </div>
                    </AddressCard>
                </div>

            </div>
            <QRCodeSanner
                isPresented={isQRCodeScannerPresented}
                onClose={() => { setIsQRCodeScannerPresented(false) }}
                onScanned={onScanned}
            />

            <WalletConnectPermission
                isPresented={isWalletConnectPermissionPresented}
                onClose={() => { setIsWalletConnectPermissionPresented(false) }}
                onApproved={onApprovedWalletConnect}
                onRejected={onRejectedWalletConnect}
            />

        </main>

    )
}