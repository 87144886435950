import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../Components/PrimaryButton'
import TopNavLogo from '../../Components/TopNavLogo'
import WalletConnectConnectedIcon from '../../Components/WalletConnectConnectedIcon'
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { useEventContext } from "../../contexts/EventContext";
import Global from '../../Global'
export default function SignupForm(props) {

    const eventContext = useEventContext()
    const navigate = useNavigate()
    const wcSdk = useWalletConnect()
    const [emailAddress, setEmailAddress] = React.useState("")
    const [role, setRole] = React.useState("")
    const [isOkToEmail, setIsOkToEmail] = React.useState(true)
    const [isLoading, setIsLoading] = React.useState(false)

    

    async function addItem(email, role, oktoemail) {


        setIsLoading(true)
        let address = wcSdk.getAccountAddress()

        const url = 'https://api.airtable.com/v0/appB2rIPDLzAmgJx3/Signup';
        const token = Global.AirTableKey;
        const data = {
            records: [
                {
                    fields: {
                        "Email": email,
                        "Role": role,
                        "OkToEmail": oktoemail,
                        "Address": address,
                    }
                },
            ],
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            const result = await response.json();
            console.log(result);
            setIsLoading(false)
            navigate(eventContext.getFullPathURL("/binding/claiming"))
        } else {
            alert("Something went wrong")
        }    
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(emailAddress, role, isOkToEmail)
        addItem(emailAddress, role, isOkToEmail)
    }

    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">
            <WalletConnectConnectedIcon />

            <div className="container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>
            <div className='h-full w-full flex flex-col items-center justify-center'>

                <div className=" flex flex-col items-center justify-center card aspect-square p-10">
                    <div className='w-full'>
                        <p className='uppercase text-primary font-bold text-sm'>Your Details</p>
                        <p className='text-xl font-bold text-white'>Please tell us about yourself to continue</p>
                        <div className='mt-5'>
                            <form onSubmit={(e) => { handleSubmit(e) }}>
                                <label className='flex flex-col gap-2 group'>
                                    <div className='flex items-center'>
                                        <span className='uppercase text-primary font-semibold text-sm'>Email address</span>
                                    </div>
                                    <input onChange={(e) => { setEmailAddress(e.target.value.trim()) }} required type="email" className="w-full text-white bg-[#1E282C] appearance-none focus:outline-none focus:ring-0 p-3 rounded invalid:ring-1 invalid:ring-[#FF005C]" placeholder="Please enter your email address" />
                                </label>

                                <label className='mt-5 flex flex-col gap-2'>
                                    <span className='uppercase text-primary font-semibold text-sm'>ROLE</span>

                                    <select onChange={(e) => { setRole(e.target.value) }} required className="w-full text-white bg-[#1E282C] appearance-none focus:outline-none focus:ring-0 p-3 rounded invalid:ring-1 invalid:ring-[#FF005C]" placeholder='Please select which option describes you best'>
                                        <option value="">Please select which option describes you best</option>
                                        <option value="institutional-investor">Institutional investor</option>
                                        <option value="professional">Professional</option>
                                        <option value="crypto-trader">Crypto trader</option>
                                        <option value="aspiring-crypto-professional">Aspiring crypto professional</option>
                                        <option value="crypto-web3-professional">Crypto/Web3 professional</option>
                                        <option value="individual-investor">Individual investor</option>
                                        <option value="nft-creator-collector">NFT Creator/Collecor</option>
                                        <option value="gamer">Gamer</option>
                                        <option value="crypto-web3-curios">Crypto/Web3 curios</option>
                                        <option value="developer">Developer</option>
                                        <option value="og-ant">OG ant</option>
                                        <option value="other">Other</option>
                                    </select>
                                </label>
                                <label className='mt-5 flex gap-2 text-white'>
                                    <input defaultChecked type="checkbox" onChange={(e) => { setIsOkToEmail(e.target.checked) }} className='w-5 h-5 text-primary' /><span>It’s cool if Neo emails me later</span>
                                </label>
                                <div className='mt-5 w-full'>
                                    {
                                        isLoading ?
                                        <div className="w-full bg-primary hover:bg-primary-hover flex items-center justify-center disabled:bg-disabled text-black text-xl px-4 py-2 rounded  font-bold focus:outline-none focus:ring-0 ">
                                            &nbsp;
                                        <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    </div>
                                    :
                                    <PrimaryButton type="submit" disabled={emailAddress.length === 0 || role.length === 0}>Claim assets</PrimaryButton>
                                    }
                                    
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )

}