import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../Components/PrimaryButton';
import TopNavLogo from '../../Components/TopNavLogo';
import AddressCard from '../Components/AddressCard';
import Ring from '../../assets/img/ring-no-shadow.png'
import RingShadow from '../../assets/img/ring-shadow.png'
import { useEventContext } from "../../contexts/EventContext";
import { useAccountContext } from '../../contexts/AccountContext';
export default function OnboardingCompleted(props) {

    const eventContext = useEventContext()
    const [searchParams, setSearchParams] = useSearchParams();
    const accountContext = useAccountContext()

    const [itemTokenId, setItemTokenId] = React.useState(searchParams.get("itemtokenid"))
    const [itemData, setItemData] = React.useState(null)

    const getData = async (itemTokenId) => {
        const [item] = await Promise.all([
            accountContext.getItemData(itemTokenId),
        ]);
        console.log(item)
        setItemData(item)
    }


    const getRingImageUrlWithColor = (color) => {
        return `https://props.coz.io/img/item/neo/2780587208/oneBand_${color}.png`
    }

    React.useEffect(()=>{
        if (itemTokenId) {
            getData(itemTokenId)
        }
        
    },[])

    return (
        
            <main className='max-w-sm mx-auto w-full h-full flex flex-col inset-0 absolute pb-4'>

                <div className="z-10 container mx-auto py-5 flex items-center justify-center">
                    <TopNavLogo />
                </div>

                <div className='h-full w-full flex flex-col items-center justify-center px-4'>

                    <div className='flex items-center gap-2 py-5'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.875 2.09289C15.19 1.33371 16.81 1.33371 18.125 2.09289L26.9814 7.20614C28.2964 7.96533 29.1064 9.36837 29.1064 10.8868V21.1132C29.1064 22.6316 28.2964 24.0347 26.9814 24.7939L18.125 29.9071C16.81 30.6663 15.19 30.6663 13.875 29.9071L5.01859 24.7939C3.70364 24.0347 2.89359 22.6316 2.89359 21.1132V10.8868C2.89359 9.36837 3.70364 7.96533 5.01859 7.20614L13.875 2.09289Z" stroke="#00E599" strokeWidth="1.5" />
                            <path d="M12.6667 20.2985L9.16667 16.7463L8 17.9304L12.6667 22.6667L22.6667 12.5174L21.5 11.3333L12.6667 20.2985Z" fill="white" />
                        </svg>
                        <p className='font-bold text-white text-lg'>Complete</p>
                    </div>

                    <div className='card aspect-square w-60 h-60 p-4 mb-5 flex items-center justify-center'>
                        <div className='relative  flex items-center justify-center'>
                            {
                                itemData ? 
                                <img className='relative w-full z-20' src={getRingImageUrlWithColor(itemData.traits[0].color)} />
                                :
                                <img className='relative w-full z-20' src={Ring} />
                            }
                            
                            {/* <img className=' w-48 scale-125  z-10 absolute -bottom-2 right-4' src={RingShadow} /> */}
                        </div>
                    </div>
                    <div className='max-w-sm text-center mb-4'>
                        <p className='font-bold text-white text-lg mb-1'>You are ready to start exploring!</p>
                        <p className='text-white text-center'>Tap-scan your OneBand at stations within the Neo booth to evolve your commemorative Texas Horned LIZARD NFT.</p>
                    </div>
                    <Link to={eventContext.getFullPathURL("/wallet")} className="w-full"><PrimaryButton >Continue</PrimaryButton></Link>

                    <div className='mt-auto bottom-5 w-full'>
                    <AddressCard />
                    </div>

                </div>

              

            </main>
        
    )
}