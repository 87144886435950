import React from "react";
import PropTypes from 'prop-types';
export const EventContext = React.createContext({});
export const EventContextProvider = ({ eventId, children }) => {

    const allowEvents = ["collect","another"]
    const [event, setEvent] = React.useState(null);

    const init = React.useCallback(async () => {

        if (eventId === undefined || eventId === null) {
            setEvent(null)
            return
        }

        console.log("allowEvents.indexOf(_eventId)", allowEvents.indexOf(eventId));
        if (allowEvents.indexOf(eventId) < 0){
            window.location.href="/eh" //force redirect for event that is supported
            setEvent(null)
            return
        }
        setEvent(eventId)
    }, []);

    React.useEffect(() => {
        console.log("_eventId", eventId);
        init()
    }, [])

    const getFullPathURL = (path) => {
        return "/" + event + path
    }

    const contextValue = {
        eventId,
        getFullPathURL
    };

    return (React.createElement(EventContext.Provider, { value: contextValue }, children));
};

export const useEventContext = () => React.useContext(EventContext);
EventContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};