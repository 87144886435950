import React from "react";
import { Routes, Route, useParams, Link, Outlet } from "react-router-dom";

import OnboardingInProgress from "./UserApp/OnboardingInProgress";
import WalletConnectPermission from "./UserApp/WalletConnectPermission";
import OnboardingCompleted from "./UserApp/OnboardingCompleted"
import WalletHome from "./UserApp/WalletHome"
import WalletAssets from "./UserApp/WalletAssets"
import WalletPFP from "./UserApp/WalletPFP";
import NFTAssetDetail from "./UserApp/NFTAssetDetail";
import ProjectDetail from "./UserApp/ProjectDetail";
import WithdrawAssets from "./UserApp/WithdrawAssets";
import ExportKey from "./UserApp/ExportKey";
import UserHome from "./UserApp/UserHome";
import { AccountContextProvider } from "./contexts/AccountContext";
import { useSearchParams } from 'react-router-dom';

import { Web3WalletContextProvider } from "./contexts/Web3WalletContext";

import { WALLET_PROJECT_ID, DEFAULT_RELAY_PROVIDER, DEFAULT_APP_METADATA, DEFAULT_LOGGER } from './constants'


function UserApp() {

  const [searchParams, setSearchParams] = useSearchParams();
  const wif = searchParams.get("wif") || localStorage.getItem("_consensus2023_wif")

  const walletWcOptions = {
    logger: DEFAULT_LOGGER,
    projectId: WALLET_PROJECT_ID,
    relayUrl: DEFAULT_RELAY_PROVIDER,
    metadata: DEFAULT_APP_METADATA
  };

  return (
      <Web3WalletContextProvider projectId={walletWcOptions.projectId} relayUrl={walletWcOptions.relayUrl} metadata={walletWcOptions.metadata}>
        <AccountContextProvider wif={wif}>
          <Routes>
            <Route path="/" element={<UserHome />}></Route>
            <Route path="/walletconnect" element={<WalletConnectPermission />}></Route>
            <Route path="/onboardinginprogress" element={<OnboardingInProgress />}></Route>
            <Route path="/onboardingcompleted" element={<OnboardingCompleted />}></Route>
            <Route path="/wallet" element={<WalletHome />}></Route>
            <Route path="/wallet/assets" element={<WalletAssets />}></Route>
            {/* <Route path="/wallet/pfp" element={<WalletPFP />}></Route> */}
            <Route path="/wallet/assets/:assetId/:tokenId" element={<NFTAssetDetail />}></Route>
            <Route path="/projects/:id" element={<ProjectDetail />}></Route>
            <Route path="/wallet/withdraw" element={<WithdrawAssets />}></Route>
            <Route path="/wallet/export" element={<ExportKey />}></Route>
          </Routes>
        </AccountContextProvider>
      </Web3WalletContextProvider>
  );
}

export default UserApp;
