import React from "react";
import PropTypes from 'prop-types';
import { default as Neon, wallet } from "@cityofzion/neon-js";
import { Item } from '@cityofzion/props-item'
import { Lizard, Utils } from '@cityofzion/nft-lizard'
import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NeonParser } from '@cityofzion/neon-parser'

export const BindingAppContext = React.createContext({});
export const BindingAppContextProvider = ({ wif, children }) => {

    const NODE = "https://mainnet1.neo.coz.io:443"

    const [account, setAccount] = React.useState(null);

    const init = React.useCallback(async () => {

        if (wif === undefined || wif === null) {
            setAccount(null)
            return
        }

        const isWif = Neon.is.wif(wif);
        if (isWif) {
            const neoAccount = new wallet.Account(wif)
            setAccount(neoAccount)
            localStorage.setItem("_binding_wif", wif)
        }
    }, []);

    const initItem = async () => {
        const item = new Item({
            scriptHash: Item.MAINNET,
            invoker: await NeonInvoker.init(NODE, account),
            parser: NeonParser,
        })
        return item
    }

    const initLizard = async () => {
        const lizard = new Lizard({
            scriptHash: Lizard.MAINNET,
            invoker: await NeonInvoker.init(NODE, account),
            parser: NeonParser,
        })
        return lizard
    }

    const mintToAddress = async (addresss) => {
        console.log("minting to ", addresss , "with account ", account)
     
        const lizardSDK = await initLizard()
        const epochId = 2 // production
        const itemEpochId = 5 //production

        const txid = await lizardSDK.offlineMintBound(
            {
                epochId: epochId,
                itemEpochId: itemEpochId,
                address: addresss
            })
        // return response
        const log = await Utils.transactionCompletion(txid)
        console.log("raw response:",log)
        
        const response = NeonParser.parseRpcResponse(log.executions[0].stack[0])    
        console.log("response", response)
        return response
    }

    const bindItemConfirmed = async (tokenId, ringPublicKey, blockIndex, signature) => {
        const itemSDK = await initItem()
        console.log(tokenId, ringPublicKey, blockIndex, signature)
        const txid = await itemSDK.bindItem(
            {
                tokenId: tokenId.toString(),
                assetPubKey: ringPublicKey,
                blockIndex: Number(blockIndex),
                signature: signature
            })
        const log = await Utils.transactionCompletion(txid)
        console.log("raw response:",log)
        
        const response = NeonParser.parseRpcResponse(log.executions[0].stack[0])    
        console.log("response", response)

        return response
    }
   
    const getItemData = async (tokenId) => {
        const item = await initItem()
        const res = item.getItemJSON(
            {
                tokenId: tokenId.toString()
            })
        return res
    }

    const getLizardData = async (tokenId) => {
        const lizard = await initLizard()
        const res = lizard.getLizardJSON(
            {
                tokenId: tokenId.toString()
            })
        return res
    }

    
    const getTokensOf = async (address) => {
        
        const lizardSDK = await initLizard()
        const itemSDK = await initItem()

        const [items, lizard] = await Promise.all([
            await itemSDK.tokensOf({address: address}),
            await lizardSDK.tokensOf({address: address})
        ])

        var balances = []
        if (items.length > 0){
            const tokenId = items[0]
            const data = await getItemData(tokenId)
            if (data) {
                balances.push(data)
            }
        }

        
        if (lizard.length > 0){
            const tokenId = lizard[0]
            if (tokenId.toString().length > 0 ) {
                const data = await getLizardData(tokenId)
                if (data) {
                    balances.push(data)
                }
            }
            
        }
        
        return balances
    }

    const getItemJSON = async (tokenId) => {
        const itemSDK = await initItem()
        return itemSDK.getItemJSON({ tokenId: tokenId })
    }

    const getLizardJSON = async (tokenId) => {
        const lizardSDK = await initLizard()
        return lizardSDK.getLizardJSON({ tokenId: tokenId })
    }

    React.useEffect(() => {
        init()
    }, [])

    const contextValue = {
        account,
        mintToAddress,
        bindItemConfirmed,
        getItemJSON,
        getLizardJSON,
        getTokensOf
    };

    return (React.createElement(BindingAppContext.Provider, { value: contextValue }, children));
};

export const useBindingAppContext = () => React.useContext(BindingAppContext);
BindingAppContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};