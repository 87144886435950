import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TopNavbarWithActions from '../Components/TopNavbarWithActions';
import { useEventContext } from "../../contexts/EventContext";
import { useAccountContext } from "../../contexts/AccountContext";
import Global from '../../Global'
import Ring from '../../assets/img/ring-no-shadow.png'
export default function WalletAssets(props) {

    const navigate = useNavigate()
    const eventContext = useEventContext()

    const accountContext = useAccountContext()
    const account = accountContext.account

    const [balances, setBalances] = React.useState([])
    const [gasBalance, setGasBalance] = React.useState(null)

    const getBalances = async () => {
        const fetchData = url => fetch(url).then(r => r.json());
        const balancesUrl = `https://dora.coz.io/api/v1/neo3/mainnet/balance/${account._address}`

        const [tokenBalances, assets] = await Promise.all([
            await accountContext.fetchBalance(),
            fetchData(balancesUrl)
        ]);

        setBalances(tokenBalances)
        const gas = assets.find((a) => { return a.asset == "0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5" })
        if (gas) {
            setGasBalance(gas)
        }
    }

    React.useEffect(() => {
        if (account) {
            getBalances()
        }
    }, [account])

    const getPath = (asset) => {
        const id = asset["id"]
        
        if (asset.name.toLowerCase() == "gas" || (id !== undefined && id === "neo")) {
            return `/projects/neo`
        }
        return `/wallet/assets/${asset.name.toLowerCase()}/${asset.tokenId}`
    }

    const getRingImageUrlWithColor = (color) => {
        return `https://props.coz.io/img/item/neo/2780587208/oneBand_${color}.png`
    }

    return (

        <main className='max-w-sm mx-auto flex flex-col'>
            <TopNavbarWithActions />

            <div className='w-full flex items-center px-4'>
                <button onClick={(e) => { navigate(-1) }} className='flex items-center text-primary font-semibold'>
                    <ChevronLeftIcon className='w-6 h-6' /><span>Back</span>
                </button>
            </div>

            <p className='text-lg font-bold text-white mt-4 mb-4 px-4'>
                Assets
            </p>

            <div className='flex flex-col w-full gap-3 px-4'>

{
    balances.length === 0 ?
    <div className='w-full flex items-center justify-center'>
    <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    </div>
                                    : null
}
                {
                    balances.map((b) => (
                        <Link key={b.name} to={eventContext.getFullPathURL(getPath(b))} className='card p-4 flex items-center gap-4'>
                            <div className=' w-10 h-10 flex items-center justify-center'>
                                <img src={b.name ==="ITEM" ? getRingImageUrlWithColor(b.traits[0].color) : b.image}/>
                            </div>
                            <p className='font-semibold text-white'>{b.name}</p>
                            <p className='ml-auto font-semibold text-primary'>{b.amount}</p>
                        </Link>
                    ))
                }

                {
                    gasBalance ?
                        <Link to={eventContext.getFullPathURL(`/projects/Neo`)} className='card p-4 flex items-center gap-4'>
                            <div className='w-10 h-10'>
                                <img src={Global.Projects.Neo.icon_url}/>
                            </div>
                            <p className='font-semibold text-white'>GAS</p>
                            <p className='ml-auto font-semibold text-primary'>{gasBalance.balance}</p>
                        </Link>
                        : null
                }


            </div>
        </main>

    )
}