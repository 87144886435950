import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";

export default function WalletConnectPermission(props) {
    const { isPresented, onClose, onApproved, onRejected } = props


    return (
        <Transition show={isPresented} as={Fragment}>
            <Dialog onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/80" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500 transition transform"
                    enterFrom="opacity-100 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-200 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-full"
                >
                    <Dialog.Panel className="h-9/10 max-h-9/10 z-[100] bg-[#1F2B30] fixed bottom-0 left-0 w-full rounded-t-2xl drop-shadow-lg flex flex-col overflow-hidden">
                        <div className='flex flex-col w-full bg-[#1F2B30] h-full'>
                            <div className="p-3 bg-mid-blue-gray flex items-center justify-between">
                                <div className=" flex-none">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " disabled onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-transparent" /></button>
                                </div>
                                <div className="text-white font-bold w-full text-center">Wallet Connect</div>
                                <div className="flex-none ml-auto">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-primary" /></button>
                                </div>
                            </div>

                            <div className="flex flex-col items-center pt-8 overflow-y-auto p-4">
                                <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M46.25 1.87639C47.6423 1.07254 49.3577 1.07254 50.75 1.87639L87.7522 23.2396C89.1445 24.0435 90.0022 25.5291 90.0022 27.1368V69.8633C90.0022 71.4709 89.1445 72.9565 87.7522 73.7604L50.75 95.1236C49.3577 95.9275 47.6423 95.9275 46.25 95.1236L9.24777 73.7604C7.85546 72.9565 6.99777 71.4709 6.99777 69.8633V27.1367C6.99777 25.5291 7.85546 24.0435 9.24777 23.2396L46.25 1.87639Z" fill="#111A1E" stroke="#00E599" />
                                    <path d="M26 32.3611V64.3324L46.6876 71.7778V39.5278L69 31.2861L48.7484 24L26 32.3611Z" fill="#00E599" />
                                    <path d="M48.0161 41.0421V58.1511L68.3121 65.5005V33.4162L48.0161 41.0421Z" fill="#00AF92" />
                                </svg>
                                <p className="font-bold text-lg mx-auto text-white mt-4">NeoConsensus2023</p>
                                <p className="text-white">NeoConsensus2023 want to connect to your wallet</p>
                            </div>

                            <div className="p-4">
                                <div className="card w-full flex p-4 rounded-xl overflow-hidden text-white">

                                    <div className="w-1/2 flex flex-col">
                                        <div className="flex items-center mb-1">
                                            <p className="font-bold text-xs text-primary uppercase">Chain</p>
                                        </div>
                                        <p className="text-text font-semibold flex items-center gap-2">
                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 3.5V16.8833L8.65991 20V6.5L18 3.05L9.52258 0L0 3.5V3.5Z" fill="#00E599" />
                                                <path d="M9.216 7.13387V14.2958L17.712 17.3723V3.9416L9.216 7.13387V7.13387Z" fill="#00AF92" />
                                            </svg>
                                            Neo N3
                                        </p>
                                    </div>

                                    <div className="w-1/2 flex flex-col">
                                        <div className="flex items-center mb-1">
                                            <p className="font-bold text-xs text-primary uppercase">Feature</p>
                                        </div>
                                        <p className="text-text font-semibold flex items-center gap-2">
                                            Get Address
                                        </p>
                                    </div>

                                </div>
                                
                            </div>

                            <div className="mt-auto p-4 flex flex-col gap-4">
                                <p className="font-semibold text-white text-center text-lg">Would you like to accept the connection?</p>
                                <PrimaryButton onClick={() => { onApproved() }}>Accept</PrimaryButton>
                                <SecondaryButton onClick={() => { onRejected() }}>Decline</SecondaryButton>
                            </div>


                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}