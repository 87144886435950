import { ArrowUpOnSquareIcon, CheckCircleIcon, ChevronRightIcon, KeyIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import TopNavLogo from '../../Components/TopNavLogo';
import AddressCard from '../Components/AddressCard';
import TopNavbarWithActions from '../Components/TopNavbarWithActions';
import SampleNFT from '../../assets/img/sampleNFT.png'
import WithdrawAssets from '../WithdrawAssets/WithdrawAssets';
import ExportKey from '../ExportKey/ExportKey';
import { useEventContext } from "../../contexts/EventContext";
import Global from '../../Global'
import Banner from '../../assets/img/lizard_loot_banner.png'
import { useAccountContext } from "../../contexts/AccountContext";
import LizardLootDialog from '../Components/LizardLootDialog'
export default function WalletHome(props) {
    const eventContext = useEventContext()
    const [isWithdrawAssetPresented, setIsWithdrawAssetPresented] = React.useState(false)
    const [isExportKeyPresented, setIsExportKeyPresented] = React.useState(false)
    const [isLizardLootDialogPresented, setIsLizardLootDialogPresented] = React.useState(false)

    const [isLoadingPFP, setIsLoadingPFP] = React.useState(false)
    const [lizardData, setLizardData] = React.useState(null)


    const projects = Global.Projects
    const accountContext = useAccountContext()
    const account = accountContext.account

    const refreshPFP = async () => {
        setIsLoadingPFP(true)
        await getBalances()
        setIsLoadingPFP(false)
    }

    const getBalances = async () => {
        let balances = await accountContext.fetchBalance()
        const lizardToken = balances.find((p)=>{return p.name === "LIZARD" })
        const lizardTokenId = lizardToken.tokenId.toString()
        const response = await accountContext.getLizardData(lizardTokenId)
        setLizardData(response)
    }

    React.useEffect(() => {
        if (accountContext.account) {
            getBalances()
        }
    }, [accountContext.account])

    const checkScanned = (projectId) => {
        if (lizardData === null) {
            return false
        }

        const found = lizardData.waypoints.filter((p)=>{return p.waypoint === projectId})
        if (found) {
            return found[0].state
        }
        return false
    }

    const totalScanned = () => {
        if (lizardData === null) {
            return 0
        }

        return lizardData.waypoints.filter((p)=>{return p.state === true}).length
    }

    return (
        <main className='max-w-sm mx-auto flex flex-col'>
            <TopNavbarWithActions onLeftButtonTapped={() => { setIsExportKeyPresented(true) }} onRightButtonTapped={() => { setIsWithdrawAssetPresented(true) }} />
            <div className="w-full container mx-auto px-4 flex items-center justify-center gap-4 h-24">
                <div className='w-1/2 h-full'>
                    <AddressCard />
                </div>

                <div className='w-1/2 overflow-hidden h-full'>

                    <Link to={eventContext.getFullPathURL("/wallet/assets")} className=" w-full  flex flex-col h-full">
                        <div className="card w-full h-full flex flex-col p-4 rounded-xl overflow-hidden text-white">

                            <div className="flex items-center mb-1.5">
                                <p className="font-bold text-sm text-white">Asset</p>

                                <button onClick={(e) => { refreshPFP() }} className="ml-auto">
                                    <ChevronRightIcon className="w-4 h-4 text-primary" />
                                </button>
                            </div>

                            <div className='mt-auto flex items-center gap-1 overflow-x-auto '>
                                {
                                    Array.from(Array(10).keys()).map((i) => (
                                        <div key={`asset-${i}`} className='flex-none'>
                                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16.5" cy="16.5" r="7" stroke="white" />
                                                <path d="M14.25 1.87639C15.6423 1.07254 17.3577 1.07254 18.75 1.87639L28.0394 7.23964C29.4317 8.04348 30.2894 9.52906 30.2894 11.1368V21.8632C30.2894 23.4709 29.4317 24.9565 28.0394 25.7604L18.75 31.1236C17.3577 31.9275 15.6423 31.9275 14.25 31.1236L4.96058 25.7604C3.56828 24.9565 2.71058 23.4709 2.71058 21.8632V11.1368C2.71058 9.52906 3.56828 8.04348 4.96058 7.23964L14.25 1.87639Z" stroke="#00E599" />
                                            </svg>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Link>

                </div>
            </div>

            <div className="w-full container mx-auto px-4 mt-3 flex flex-col">
                <div className='card aspect-square w-full relative overflow-hidden flex items-center justify-center'>
                    <Link to={eventContext.getFullPathURL(`/wallet/assets/lizard/${lizardData && lizardData.tokenId}`)}>
                        {
                            lizardData ?
                            <img src={lizardData.image} className="w-full h-full object-cover z-10" />
                            : 
                            <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        
                    </Link>
                    <div className='absolute z-20 bottom-0 left-0 w-full bg-gradient-to-t from-black h-2/3 pointer-events-none'></div>
                    <div className='absolute z-50 bottom-0 left-0 p-4 flex items-end w-full'>
                        <Link to={eventContext.getFullPathURL(`/wallet/assets/lizard/${lizardData && lizardData.tokenId}`)} >
                            <p className='text-2xl font-black text-white'>Your LIZARD NFT</p>
                            <p className='font-bold text-white'>{lizardData && lizardData.description} {lizardData && "#" + String(lizardData.tokenId).padStart(3, '0')}</p>
                        </Link>
                        <div className='ml-auto flex items-end'>
                            {
                                isLoadingPFP ?
                                    <svg className="animate-spin h-4 w-4 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    :
                                    <button onClick={(e) => { refreshPFP() }} className='p-0'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.65 2.35C12.2 0.9 10.21 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C11.73 16 14.84 13.45 15.73 10H13.65C12.83 12.33 10.61 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.66 2 11.14 2.69 12.22 3.78L9 7H16V0L13.65 2.35Z" fill="#00E599" />
                                        </svg>
                                    </button>
                            }
                        </div>
                    </div>
                </div>
                <div className='mt-3 card  w-full relative pt-4 px-4'>
                    <div className='flex items-center w-full mb-1'>
                        <p className='font-bold text-xl text-white'>Checklist</p>
                        <div className='ml-auto'>
                            <span className='px-3 py-0.5 ring-1 rounded-full ring-primary text-white text-sm'>
                                {totalScanned()} of {Object.keys(projects).length}
                            </span>
                        </div>
                    </div>
                    <p className='text-white'>
                        Visit every station to collect every trait
                    </p>

                    <div className='flex flex-col w-full divide-y divide-white/10 mt-2'>
                        {
                            Object.keys(projects).map((key) => (
                                <Link key={key} to={eventContext.getFullPathURL(`/projects/${projects[key].id}`)} className='flex items-center gap-4 py-4 hover:bg-black/20'>
                                    <div className='w-12 h-12  flex items-center justify-center'>
                                        <img src={projects[key].icon_url} className='h-12 w-12 object-contain' />
                                    </div>
                                    <p className='text-white font-semibold'>{projects[key].name}</p>
                                    <div className='ml-auto flex items-center gap-4'>
                                        {
                                            checkScanned(key) ?
                                            <CheckCircleIcon className='w-6 h-6 text-white' />
                                            : null
                                        }
                                        
                                        <ChevronRightIcon className='w-6 h-6 text-primary' />
                                    </div>
                                </Link>
                            ))
                        }

                    </div>
                </div>

                <div className='mt-3 mb-4' onClick={(e) => { setIsLizardLootDialogPresented(true) }}>
                    <img src={Banner} />
                </div>

            </div>
            <WithdrawAssets isPresented={isWithdrawAssetPresented} onClose={() => { setIsWithdrawAssetPresented(false) }} />
            <ExportKey isPresented={isExportKeyPresented} onClose={() => { setIsExportKeyPresented(false) }} />
            <LizardLootDialog isPresented={isLizardLootDialogPresented} onClose={() => { setIsLizardLootDialogPresented(false) }} />
        </main>
    )
}