
import * as React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAccountContext } from "../../contexts/AccountContext";
import LoadingGreen from '../../assets/img/loading-green.png'
import LoadingShadow from '../../assets/img/loading-shadow.png'
import AddressCard from '../Components/AddressCard';
import { useEventContext } from "../../contexts/EventContext";
import ContinueOnTablet from '../../assets/img/continue-on-tablet.png'
export default function OnboardingInProgress(props) {
    const eventContext = useEventContext()
    const accountContext = useAccountContext()
    const navigate = useNavigate()
    const checkIfAddressHasSuccessfullyBindThering = async () =>{

    
        const balance = await accountContext.fetchBalance()
            //look for Item Data and then check `asset` field if there is a value meaning that a user has already bind the ring
        const itemData = balance.find((i)=>{return i.name === "ITEM"})
        if (itemData) {
            console.log("this account has item: ",itemData)
            if (itemData.asset !== undefined) {
                navigate(eventContext.getFullPathURL(`/onboardingcompleted?itemtokenid=${itemData.tokenId.toString()}`), {replace: true})    
            }
        }     
    }

    React.useEffect(()=>{
        if (accountContext.account){
            checkIfAddressHasSuccessfullyBindThering()    
        }
    },[accountContext.account])
    

    return (

        <main className='max-w-sm mx-auto  w-full h-full flex flex-col inset-0 absolute pb-4'>
            <div className='h-full w-full flex flex-col items-center justify-center'>
                <div className='flex flex-col items-center justify-center mb-8'>
                    <img src={ContinueOnTablet} className='w-60'/>
                </div>
                <Link className='flex flex-col items-center justify-center px-8 text-center' to={eventContext.getFullPathURL("/onboardingcompleted")}>
                    <p className='text-white font-bold text-lg mb-1'>Please continue on the tablet</p>
                    <p className='text-white'>If you need help please ask a booth representative for assistance</p>
                </Link>

                <div className='flex items-center justify-center mt-8'>
                    <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            </div>
            <div className='mt-auto px-4  w-full'>
                <AddressCard />
            </div>
        </main>

    )
}