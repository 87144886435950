import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import TopNavLogo from '../../Components/TopNavLogo'
import Ring from '../../assets/img/binding-completed-ring.png'

import Step1 from '../../assets/img/binding-completed-step-1.png'
import Step2 from '../../assets/img/binding-completed-step-2.png'
import WalletConnectConnectedIcon from '../../Components/WalletConnectConnectedIcon';
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import Global from '../../Global';
import { useAccountContext } from '../../contexts/AccountContext';
export default function ScanToBindCompleted(props) {


    const wcSdk = useWalletConnect()
    

    const [searchParams, setSearchParams] = useSearchParams();

    const [address, setAddress] = React.useState(searchParams.get("address"))
    const [signature, setSignature] = React.useState(searchParams.get("signature"))
    const [publickey, setPublickey] = React.useState(searchParams.get("publickey"))
    const [blockIndex, setBlockIndex] = React.useState(searchParams.get("blockindex"))
    const [blockHash, setBlockHash] = React.useState(searchParams.get("blockhash"))
    
    React.useEffect(()=>{
        wcSdk.disconnect()
        addRecord(address, publickey, signature, blockIndex, blockHash)
    },[])

    async function addRecord(_address, _publicKey, _signature, _blockIndex, _blockHash) {

        const url = 'https://api.airtable.com/v0/appB2rIPDLzAmgJx3/bind';
        const token = Global.AirTableKey;
        const data = {
            records: [
                {
                    fields: {
                        "address": _address,
                        "publicKey": _publicKey,
                        "signature": _signature,
                        "blockIndex": _blockIndex,
                        "blockHash": _blockHash,
                    }
                },
            ],
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response.status === 200) {
            const result = await response.json();
            console.log(result);
        } else {
            console.log("error");
        }    
    }


    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">
            <WalletConnectConnectedIcon />
            <div className="w-full container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>
            <div className='h-full w-full flex flex-col items-center justify-center text-white'>

                <img src={Ring} className="h-40 mb-5 -translate-x-5" />
                <div className='flex items-center gap-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-primary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <p className='text-xl font-bold text-white'>Binding Complete!</p>
                </div>

                <div className='container mx-auto mt-10 flex items-center justify-between gap-20 max-w-4xl'>
                    <div className='flex flex-col items-center gap-4'>
                        <div className='card w-48 h-48'>
                            <img src={Step1}/>
                        </div>
                        <p className='text-white w-48 text-center'>Your OneBand is now your bridge between the physical world and the blockchain world.</p>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <div className='card w-48 h-48'>
                            <img src={Step2}/>
                        </div>
                        <p className='text-white w-48 text-center'>Use it to interact with the Neo booth and evolve your LIZARD NFT.</p>
                    </div>
                    <div className='flex flex-col items-center gap-4'>
                        <div className='card w-48 h-48'></div>
                        <p className='text-white w-48 text-center'>Keep track of your progress and assets in your mobile browser wallet. </p>
                    </div>
                </div>
            </div>

            <div className='absolute bottom-0 right-0 p-4'>
                
            </div>
        </div>
    )

}