import React from "react";
import PropTypes from 'prop-types';
import { default as Neon, wallet } from "@cityofzion/neon-js";
import { Item } from '@cityofzion/props-item'
import { Lizard, Utils } from '@cityofzion/nft-lizard'
import { NeonInvoker } from '@cityofzion/neon-invoker'
import { NeonParser } from '@cityofzion/neon-parser'
import Global from "../Global";


export const ScanStationAppContext = React.createContext({});
export const ScanStationAppContextProvider = ({ wif, projectId, children }) => {

    const NODE = "https://mainnet1.neo.coz.io:443"

    const [project, setProject] = React.useState(null)
    const [account, setAccount] = React.useState(null);

    const init = React.useCallback(async () => {

        if (projectId !== undefined) {
            const p = Global.Projects[projectId]
            if (p) {
                setProject(p)
                localStorage.setItem("_scanstation_project", projectId)
            } else {
                alert("unable to find project " ,projectId)
            }
        }
    

        if (wif === undefined || wif === null) {
            setAccount(null)
            return
        }

        const isWif = Neon.is.wif(wif);
        if (isWif) {
            const neoAccount = new wallet.Account(wif)
            setAccount(neoAccount)
            localStorage.setItem("_scanstation_wif", wif)
        }
    }, []);

    const initItem =async ()=> {
         const item = new Item({
            scriptHash: Item.MAINNET,
            invoker: await NeonInvoker.init(NODE, account),
            parser: NeonParser,
        })
        return item
    }

    const initLizard = async ()=> {
        const lizrd = new Lizard({
           scriptHash: Lizard.MAINNET,
           invoker: await NeonInvoker.init(NODE, account),
           parser: NeonParser,
       })
       return lizrd
   }

    const getLizardWithItemWithPublicKey = async (publicKey) => {
        const lizard = await initLizard()
        console.log(publicKey)
        const response = lizard.getLizardWithItem({assetPubKey: publicKey})
        return response
    } 

    const confirmScan = async (ringPublicKey, blockIndex, signature) => {
        const lizardSDK = await initLizard();
        const txid = await lizardSDK.sighting({assetPubKey: ringPublicKey, blockIndex: blockIndex, signature: signature})
        const log = await Utils.transactionCompletion(txid)
        console.log("raw response:",log)
        
        const response = NeonParser.parseRpcResponse(log.executions[0].stack[0])    
        console.log("response", response)
        return response
    }

    React.useEffect(() => {
        init()
    }, [])

    const contextValue = {
        account,
        project,
        getLizardWithItemWithPublicKey,
        confirmScan
    };

    return (React.createElement(ScanStationAppContext.Provider, { value: contextValue }, children));
};

export const useScanStationAppContext = () => React.useContext(ScanStationAppContext);
ScanStationAppContextProvider.propTypes = {
    children: PropTypes.any.isRequired,
};