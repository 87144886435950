import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useAccountContext } from "../../contexts/AccountContext";
export default function AddressCard(props) {
    const accountContext = useAccountContext()
    const account = accountContext.account
    
    const [isCopied, setIsCopied] = React.useState(false)

    if (account === null) {
        return (
            <div></div>
        )
    }
    

    const copyToClipboard = () => {
        navigator.clipboard.writeText(account.address)
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1000);
    }

    return (

        <div className="card w-full h-full flex flex-col p-4 rounded-xl overflow-hidden text-white">

            <div className="flex items-center mb-1">
                <p className="font-bold text-sm text-white">Your address</p>
                <button onClick={(e)=>{copyToClipboard()}} className="ml-auto">
                    <DocumentDuplicateIcon className="w-4 h-4 text-primary" />
                </button>
            </div>
            <p className="text-sm worksans text-primary font-semibold  truncate overflow-ellipsis">{account.address}</p>

            {props.children}
        </div>

    )
}