import React from "react";
import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline";
import { QrScanner } from '@yudiel/react-qr-scanner';


export default function QRCodeScanner(props) {
    const { isPresented, onClose, onScanned } = props

    const [result, setResult] = React.useState("");

    React.useEffect(() => {
        if (isPresented === false) {
            return
        }
        getDevices()
            .then((available) => {
                setDevices(available)
            })

    }, [isPresented]);


    const [devices, setDevices] = React.useState(null)
    const [videoConstraints, setVideoConstraints] = React.useState(null)
    const [hideCamera, setHideCamera] = React.useState(true)

    const getDevices = async () => {
        await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        return await navigator.mediaDevices.enumerateDevices()
    }

    React.useEffect(() => {

        if (devices !== null) {
            const first = devices.filter((d) => { return d.label.toLowerCase().includes("back") });
            if (first.length > 0) {
                setVideoConstraints({ deviceId: first[0].deviceId })
                setHideCamera(false)
            } else {
                setVideoConstraints({ deviceId: "", facingMode: 'environment' })
                setHideCamera(false)
            }
        }
    }, [devices])

    const onChangeVideoInput = (e) => {
        setVideoConstraints({ deviceId: e.target.value, aspectRatio: 1.0 })
        setHideCamera(true)
        setTimeout(() => {
            setHideCamera(false)
        }, 500)
    }

    React.useEffect(()=> {
        if (result === "") {
            return
        }
        onScanned(result.trim())
    },[result])


    return (
        <Transition show={isPresented} as={Fragment}>
            <Dialog onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300 transition transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-200 transition transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <div className="fixed inset-0 bg-black/80" />

                </Transition.Child>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500 transition transform"
                    enterFrom="opacity-100 translate-y-full"
                    enterTo="opacity-100 translate-y-0"
                    leave="ease-in duration-200 transition transform"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-full"
                >
                    <Dialog.Panel className="h-9/10 max-h-9/10 z-[100] bg-[#1F2B30] fixed bottom-0 left-0 w-full rounded-t-2xl drop-shadow-lg flex flex-col overflow-hidden">
                        <div className='flex flex-col w-full h-full' >
                            <div className="p-3 bg-mid-blue-gray flex items-center justify-between">
                                <div className=" flex-none">
                                    <button className="appearance-none focus:outline-none focus:ring-0 ring-0 " disabled onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-transparent" /></button>
                                </div>
                                <div className="text-white font-bold w-full text-center">Wallet Connect</div>
                                <div className="flex-none ml-auto">
                                    <button className=" appearance-none focus:outline-none focus:ring-0" onClick={() => onClose()}><XMarkIcon className="w-6 h-6 text-primary" /></button>
                                </div>
                            </div>

                            <div className=" flex flex-col h-full w-full bg-[#1F2B30] overflow-y-auto">
                                {
                                    isPresented ?
                                        <QrScanner
                                            constraints={videoConstraints}
                                            onDecode={(result) => onScanned(result)}
                                            onError={(error) => console.log(error?.message)}
                                        />
                                        : null
                                }

                                <div className="mt-auto p-4 w-full">
                                    <select value={videoConstraints ? videoConstraints.deviceId : ""} className="w-full  bg-transparent text-white text-xs rounded" onChange={onChangeVideoInput}>
                                        {
                                            devices && devices.filter((d) => { return d.kind == "videoinput" }).map((d) => (
                                                <option key={d.deviceId} value={d.deviceId}>
                                                    {d.label}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className="p-4 h-full w-full bg-[#1F2B30]">
                                <input onChange={(e) => setResult(e.target.value)} value={result} type="text" className="appearance-none w-full p-4 ring-0 border-0 bg-transparent" />
                            </div>
                        </div>
                    </Dialog.Panel>
                </Transition.Child>
            </Dialog>
        </Transition>
    )
}