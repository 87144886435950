import { Routes, Route, useParams, Link, Outlet } from "react-router-dom";

import BindingApp from "./BindingApp";
import UserApp from "./UserApp";
import ScanStationApp from "./ScanStationApp"
import { EventContextProvider } from "./contexts/EventContext";
function App() {

  const { eventId } = useParams()

  return (
    <EventContextProvider eventId={eventId}>
      <Routes>
        <Route path={`/binding/*`} element={<BindingApp />}></Route>
        <Route path={`/station/*`} element={<ScanStationApp />}></Route>
        <Route path={`/*`} element={<UserApp />}></Route>
      </Routes>
    </EventContextProvider>
  );
}

export default App;
