import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import TopNavLogo from '../../Components/TopNavLogo'
export default function WalletConnectQRCode(props) {

    return (
        <div className="bg-pos w-full h-full inset-0 absolute flex flex-col">
            <div className="container mx-auto py-5 flex items-center justify-center">
                <TopNavLogo />
            </div>
            <div className='h-full w-full flex flex-col items-center justify-center text-white'>

                <p> WalletConnectQRCode</p>

            </div>
            
        </div>
    )

}