import React from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";

import BindingHome from "./BindingApp/BindingHome";
import SignupForm from "./BindingApp/SignupForm";
import WalletConnectQRCode from "./BindingApp/WalletConnectQRCode";
import ClaimingInProgress from "./BindingApp/ClaimingInProgress";
import ScanToBind from "./BindingApp/ScanToBind";
import ScanToBindCompleted from "./BindingApp/ScanToBindCompleted";
import { WalletConnectProvider } from "@cityofzion/wallet-connect-sdk-react";
import { APP_PROJECT_ID } from './constants'
import { BindingAppContextProvider } from "./contexts/BindingAppContext";
function BindingApp() {

  const wcOptions = {
    projectId: APP_PROJECT_ID,
    relayUrl: "wss://relay.walletconnect.com",
    metadata: {
      name: "Neo Consensus 2023",
      description: "Neo at Consensus 2023",
      url: "https://welcome.neo.org/",
      icons: ["https://neo3.azureedge.net/images/logo%20files-dark.svg"],
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const wif = searchParams.get("wif") || localStorage.getItem("_binding_wif")

  return (
    //start from /binding
    <BindingAppContextProvider wif={wif}>
      <WalletConnectProvider autoManageSession={true} options={wcOptions}>
        <Routes>
          <Route path="/" element={<BindingHome />}></Route>
          <Route path="/walletconnect" element={<WalletConnectQRCode />}></Route>
          <Route path="/signup" element={<SignupForm />}></Route>
          <Route path="/claiming" element={<ClaimingInProgress />}></Route>
          <Route path="/scan" element={<ScanToBind />}></Route>
          <Route path="/scan/completed" element={<ScanToBindCompleted />}></Route>
        </Routes>
      </WalletConnectProvider>
    </BindingAppContextProvider>
  );
}

export default BindingApp;
