import {SessionTypes} from "@walletconnect/types";

export const DEFAULT_RELAY_PROVIDER = "wss://relay.walletconnect.com";

export const WALLET_PROJECT_ID = '2ea05e713ee3c0efe30ec6b7bb5baf17';
export const APP_PROJECT_ID = '56de852a69580b46d61b53f7b3922ce1';

export const DEFAULT_METHODS = [
  "invokeFunction",
  "testInvoke",
  "signMessage",
  "verifyMessage",
  "getapplicationlog",
  "getrawmempool",
  "getcontractstate",
  "findstates"
];
export const DEFAULT_AUTOACCEPT_METHODS = [
  "testInvoke",
  "verifyMessage",
  "getapplicationlog",
  "getrawmempool",
  "getcontractstate",
  "findstates"
];

export const DEFAULT_LOGGER = "debug";

export const DEFAULT_CHAIN = 'neo3:mainnet'
export const DEFAULT_NETWORKS = {
  "neo3:testnet": { url: "https://testnet1.neo.coz.io:443", name: 'Testnet' },
  'neo3:mainnet': { url: "http://seed1.neo.org:10332", name: 'Mainnet' },
  'neo3:private': { url: null, name: 'Private Network' }
}
export const DEFAULT_BLOCKCHAINS = ['neo3']
export const DEFAULT_NAMESPACES = {
  [DEFAULT_BLOCKCHAINS[0]]: {
    accounts: [],
    methods: [...DEFAULT_METHODS],
    events: []
  }
}

export const DEFAULT_APP_METADATA = {
  name: "Wallet for Consensus 2023",
  description: "Wallet for Consensus 2023",
  url: "https://neo.org",
  icons: ["https://neo3.azureedge.net/images/logo%20files-dark.svg"],
};