import React from "react";
import PropTypes from 'prop-types';
import { Core } from "@walletconnect/core";
import { Web3Wallet } from "@walletconnect/web3wallet";
import  {getSdkError} from '@walletconnect/utils'

export const Web3WalletContext = React.createContext({});
export const Web3WalletContextProvider = ({ projectId, relayUrl, metadata, children }) => {

    const [web3Wallet, setWeb3Wallet] = React.useState(null);
    const [initialized, setInitialized] = React.useState(false)

    const [sessionProposals, setSessionProposals] = React.useState([])
    const [sessions, setSessions] = React.useState([]);
    const [requests, setRequests] = React.useState([]);

    const [onRequestCallback, setOnRequestCallback] = React.useState(null)
    const [autoAcceptCallback, setAutoAcceptCallback] = React.useState(null)

    const init = React.useCallback(async () => {
        const core = new Core({
            logger: 'debug',
            projectId: projectId,
            relayUrl: relayUrl,
        });

        const w = await Web3Wallet.init({
            core,
            metadata: metadata,
        });
        setWeb3Wallet(w)
    }, []);

    const pair = async (data) => {
        try {
            const uri = typeof data === 'string' ? data : ''

            if (!uri) return
            if (!web3Wallet) {
                throw new Error('Client is not initialized')
            }
            console.log("web3wallet pair to uri =", uri);
            await web3Wallet.core.pairing.pair({ uri });
        } catch (error) {
            throw new Error('client Pair Error')
        }
    }

    const approveProposal = async (proposal, accountsAndChains, namespacesWithoutAccounts ) => {

        const accounts = accountsAndChains.map((acc) => `${acc.chain}:${acc.address}`);
        const chains = accountsAndChains.map((acc) => acc.chain);
        const namespaces = Object.keys(namespacesWithoutAccounts).reduce((result, key) => {
            result[key] = Object.assign(Object.assign({}, namespacesWithoutAccounts[key]), { accounts, chains });
            return result;
        }, {});

        const session = await web3Wallet.approveSession({
            id: proposal.id,
            namespaces,
          });
        setSessionProposals((old) => old.filter((i) => i !== proposal));
        setSessions([session]);
    }

    const rejectProposal = async (proposal) => {
        const session = await web3Wallet.rejectSession({
            id: proposal.id,
            reason: getSdkError("USER_REJECTED_METHODS"),
          });
          setSessionProposals((old) => old.filter((i) => i !== proposal));
    }

    const disconnect = async () => {
        if (!web3Wallet) {
            throw new Error('Client is not initialized');
        }
        sessions.forEach((s) => {
             web3Wallet.disconnectSession({
                topic: s.topic,
                reason:  getSdkError("USER_DISCONNECTED"),
            });
        });
        
        setSessions([]);
    }

    const approveRequest = async (event) => {
        if (!web3Wallet) {
            throw new Error('web3Wallet is not initialized')
        }
        const { topic, params, id } = event
        const result = {}
        const response = { id, result: result, jsonrpc: '2.0' }
        await web3Wallet.respondSessionRequest({topic, response})
    }

    const rejectRequest = async (event) => {
        if (!web3Wallet) {
            throw new Error('web3Wallet is not initialized')
        }
        const { topic, params, id } = event

          const response = {
            id,
            jsonrpc: '2.0',
            error: {
              code: 5000,
              message: 'User rejected.'
            }
          }

          await web3Wallet.respondSessionRequest({ topic, response })
    }

    const onSessionRequest = (event) => {
        const { topic, params, id } = event
        const { request } = params
        const requestParamsMessage = request.params[0]
      
        setRequests((old) => [...old.filter((i) => i.id !== id), event])
    }

    const onSessionProposal = React.useCallback((proposal) => {
        setSessionProposals((old) => [...old, proposal])
    })

    React.useEffect(() => {
        if (web3Wallet === null) {
            return;
        }

        let existingSession = web3Wallet.getActiveSessions()
        
        if (Object.keys(existingSession).length > 0) {
             setSessions([existingSession]);
        }
        setInitialized(true)
        
    }, [web3Wallet])


    const onRequestListener = React.useCallback((listener) => {
        setOnRequestCallback(() => listener)
      }, [])
      const autoAcceptIntercept = React.useCallback((listener) => {
        setAutoAcceptCallback(() => listener)
      }, [])

    React.useEffect(() => {
        if (initialized === false || web3Wallet === null) {
            return
        }
        console.log("web3Wallet is initialized")
        web3Wallet.on('session_request', onSessionRequest)
        web3Wallet.on('session_proposal', onSessionProposal)
        web3Wallet.on('session_delete', () => {
            if (!web3Wallet) {
                throw new Error('Client is not initialized');
            }
            console.log("session_delete is fired");
            setSessions([]);
        });

        console.log("subscribed to events")
    }, [initialized, onSessionProposal])

    React.useEffect(() => {
        init()
    }, [])

    const contextValue = {
        web3Wallet,
        initialized,
        sessionProposals,
        sessions,
        pair,
        approveProposal,
        rejectProposal,
        disconnect,
        requests,
        approveRequest,
        rejectRequest,
    };

    return (React.createElement(Web3WalletContext.Provider, { value: contextValue }, children));
};

export const useWeb3WalletContext = () => React.useContext(Web3WalletContext);
Web3WalletContextProvider.propTypes = {
    projectId: PropTypes.any.isRequired,
    relayUrl: PropTypes.any.isRequired,
    metadata: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
};