import { useNavigate } from 'react-router-dom';
import ConnectedIcon from '../assets/img/connected-icon.png'
import { useWalletConnect } from "@cityofzion/wallet-connect-sdk-react";
import { useEventContext } from "../contexts/EventContext";
export default function WalletConnectConnectedIcon(props) {

    const eventContext = useEventContext()
    const navigate = useNavigate()
    const wcSdk = useWalletConnect()

    const disconnect = () => {
        wcSdk.disconnect()
        navigate(eventContext.getFullPathURL("/binding"))
    }
    return (
        <div className='absolute top-4 right-4 z-50'>
            {
                wcSdk.isConnected() ?
                <button className='z-50' onClick={(e)=>{disconnect()}}>
                    <img src={ConnectedIcon} className="h-10" />
                </button>
                    : 
                null
            }

        </div>
    )
}