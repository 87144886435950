import TopNavLogo from "../../Components/TopNavLogo";
import { KeyIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
export default function TopNavbarWithActions(props) {

    const {onLeftButtonTapped, onRightButtonTapped} = props

    return (
        <div className="z-10 container mx-auto px-4 py-5 flex items-center justify-center gap-4">
            <div className=''>
                <button onClick={onLeftButtonTapped} className='rounded-full bg-[#8392A4] w-8 h-8 flex items-center justify-center'><KeyIcon className='w-4 h-4' /></button>
            </div>
            <div className='w-full flex items-center justify-center'>
                <TopNavLogo />
            </div>
            <div className=''>
                {/* <button onClick={onRightButtonTapped} className='rounded-full bg-[#8392A4] w-8 h-8 flex items-center justify-center'><PaperAirplaneIcon className='w-4 h-4' /></button> */}
            </div>
        </div>

    )
}